import axios from "axios";

const sysConst = require('../utils/SysConst');
const dingUtil = require('../utils/DingUtils');
const localUtil = require('../utils/LocalUtils');

// 钉钉：查询用户详情，若组织下可以取到该用户信息，则 执行回调函数
export const loginDingTalk = (callback) => {
    // TODO 脱离钉钉环境代码
    // localUtil.setSessionItem(sysConst.DING_USER_INFO, "232768324719977641");
    // localUtil.setSessionItem(sysConst.DING_USER_ROLES, "集团经营驾驶舱:集团经营驾驶舱,大连兆和:制造总监,大连兆和:生产主管,大连兆和:兆和事业部总经理,默认:主管");
    // localUtil.setSessionItem(sysConst.DING_USER_INFO, {"user-id":"232768324719977641", "roleList":"集团经营驾驶舱:集团经营驾驶舱"});
    // 钉钉用户信息
    let userId = localUtil.getSessionItem(sysConst.DING_USER_ID);
    // 不存在 钉钉用户信息，调用钉钉接口，查询信息
    if (typeof(userId) == "undefined" || userId === '' || userId == null) {
        // 获取免登授权码，并执行回调函数
        dingUtil.getCode(code => {
            // 获取免登授权码成功时，执行下面 回调
            axios.get(sysConst.DOMAIN + "/login?authCode=" + code).then(res => {
                if (res && res.data.success) {
                    // 将 员工信息 存入 SESSION
                    // localUtil.setSessionItem(sysConst.AUTH_TOKEN, res.result.status);
                    // 钉钉中的 工号
                    localUtil.setSessionItem(sysConst.DING_JOB_NUM, res.data.data.jobNum);
                    // 钉钉中的 用户ID
                    localUtil.setSessionItem(sysConst.DING_USER_ID, res.data.data.userId);
                    // 钉钉中的 用户名
                    localUtil.setSessionItem(sysConst.DING_USER_NM, res.data.data.userName);
                    // 钉钉中的 角色
                    localUtil.setSessionItem(sysConst.DING_USER_ROLES, res.data.data.roleList);
                    // 钉钉中的 角色
                    localUtil.setSessionItem(sysConst.DING_USER_INFO, res.data.data);

                    // 钉钉用户 角色列表 如下所示：用来区分权限 显示页面用
                    // let arr = res.data.data.roleList.split(",");
                    // arr.map(function (item, index, self) {
                    //     alert(item);
                    // });

                    callback(res.data.data);
                } else {
                    alert("loginDingTalk：获取免登授权码失败！");
                }
            }).catch(error => {
                alert("loginDingTalk：获取免登授权码异常结束！");
                console.log("loginDingTalk：获取免登授权码异常结束！ --->" + JSON.stringify(error))
            })
        });
    } else {
        // setTimeout(function() {
        //     localUtil.setSessionItem(sysConst.DING_USER_ID, "232768324719977641");
        //     localUtil.setSessionItem(sysConst.DING_USER_ROLES, "集团经营驾驶舱:集团经营驾驶舱,大连兆和:免董事长总经理审批,大连兆和:部门经理级以上人员,大连兆和:制造总监,大连兆和:生产主管,大连兆和:大连总部经理级以上人员及事业部总,大连兆和:兆和事业部总经理,默认:主管");
        //     // localUtil.setSessionItem(sysConst.DING_USER_ROLES, "");
        // }, 1000);

        // 存在 钉钉用户信息，执行回调
        callback(localUtil.getSessionItem(sysConst.DING_USER_INFO));
    }
};

// // 调用接口登录钉钉-获取corpId   ※※※※※ 为了效率考虑，这个定义为常量 放入前端，不需要调用接口了 ※※※※※
// export const loginDingTalk = async () => {
//     await axios.get(sysConst.DOMAIN + "/getCorpId")
//         .then(res => {
//             if (res.data) {
//                 loginDingAction(res.data.data);
//             }
//         }).catch(error => {
//             console.log("corpId err, " + JSON.stringify(error))
//         }
//     )
// };