import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Row, Col, DatePicker, Tabs, Button, Spin, Modal, Select} from 'antd';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';
import {SearchOutlined, DeleteOutlined, SyncOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

const { confirm } = Modal;
const sysConst = require('../utils/SysConst');
const commonUtil = require('../utils/CommonUtil');
const dingUtil = require('../utils/DingUtils');

const SysSetting = () => {
    // 页面调用接口时，是否显示加载中动画
    const [loading, setLoading] = useState(false);
    // 查询年份 状态
    const [year, setYear] = useState("");
    // 数据类型 状态
    const [type, setType] = useState(sysConst.DATA_TYPE[0]);
    // 处理结果
    const [result, setResult] = useState("");

    // 第一次渲染时调用
    useEffect(() => {
        // 使用手机钉钉打开时, 设置标题内容
        dingUtil.setDingTitle('系统设置');
        // 设置默认年
        setYear(dayjs().year());
    }, []);

    const changeTab = (key) => {};

    const queryData = () => {
        // 加载中动画
        setLoading(true);
        // 根据条件 调用后端接口
        axios.post(sysConst.DOMAIN + "/data/approval/history/size", {
            year: year, dataType: type.value
        }).then(res => {
            if (res.status === 200) {
                setResult(`${year}年度 ${type.label} 历史数据数是: ${res.data}`);
            } else {
                setResult(`${year}年度 ${type.label} 查询历史数据失败！`);
                console.log("res.status is: " + res.status);
            }
            setLoading(false);
        }).catch(error => {
            setResult(`${year}年度 ${type.label} 查询历史数据失败！`);
            setLoading(false);
            console.log("queryData err, " + JSON.stringify(error));
        })
    };

    const showDeleteConfirm = () => {
        confirm({
            title: '确定执行删除操作么？',
            icon: <ExclamationCircleFilled/>,
            content: '',
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk() {
                removeData();
                console.log('OK');
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const removeData = () => {
        // 加载中动画
        setLoading(true);
        // 根据条件 调用后端接口
        axios.post(sysConst.DOMAIN + "/data/approval/history/remove", {
            year: year, dataType: type.value
        }).then(res => {
            if (res.status === 200) {
                setResult(`${year}年度 ${type.label} 删除历史数据成功！`);
            } else {
                setResult(`${year}年度 ${type.label} 删除历史数据失败！`);
                console.log("res.status is: " + res.status);
            }
            setLoading(false);
        }).catch(error => {
            setResult(`${year}年度 ${type.label} 删除历史数据失败！`);
            console.log("removeData err, " + JSON.stringify(error));
            setLoading(false);
        })
    };

    const reloadData = () => {
        // 加载中动画
        setLoading(true);
        setResult('此处理可能需要大量时间，请不要操作页面，耐心等待...');
        // 根据条件 调用后端接口
        axios.post(sysConst.DOMAIN + "/data/approval/history/reload", {
            year: year, dataType: type.value
        }).then(res => {
            if (res.status === 200) {
                setResult(`${year}年度 ${type.label} 更新历史数据成功！`);
            } else {
                setResult(`${year}年度 ${type.label} 更新历史数据失败！`);
                console.log("res.status is: " + res.status);
            }
            setLoading(false);
        }).catch(error => {
            setResult(`${year}年度 ${type.label} 更新历史数据失败！`);
            console.log("reloadData err, " + JSON.stringify(error));
            setLoading(false);
        })
    };

    const onChange = (date) => {
        if (date) {
            setResult("");
            setYear(commonUtil.getYear(date));
            console.log('Date: ', commonUtil.getYear(date));
        } else {
            setResult("");
            setYear(commonUtil.getYear(new Date()));
            console.log('Clear', commonUtil.getYear(new Date()));
        }
    };

    const disabledDate = (current) => {
        return current < dayjs('2020-01-01') || current > dayjs().endOf('day');
    };

    // const handleChange = (value) => {
    //     console.log(`selected ${value}`);
    // };

    return (
        <>
            {/* 加载中动画，居中显示 */}
            <div style={{zIndex: 999, position: 'absolute', top: '40%', left: '46%'}}>
                <Spin size="large" spinning={loading}/>
            </div>

            <Tabs
                defaultActiveKey="1"
                onChange={changeTab}
                tabBarStyle={{paddingLeft:5}}
                items={[
                    {
                        key: '1',
                        label: `历史数据`,
                        children: (
                            <Row>
                                <Col span={6} style={{padding: 5}}>
                                    <DatePicker picker="year" locale={locale} bordered={true} allowClear={false}
                                                defaultValue={dayjs()} disabledDate={disabledDate} onChange={onChange}/>
                                </Col>

                                <Col span={16} style={{padding: 5}}>
                                    <Select options={sysConst.DATA_TYPE} value={type}
                                        onChange={(value) => {setType(commonUtil.getJsonItem(sysConst.DATA_TYPE, value))}}
                                    />
                                </Col>

                                <Col span={24} style={{padding: 5, marginTop : 5}}>结果：{result}</Col>

                                <Col span={8} style={{padding: 5}}>
                                    <Button type="primary" icon={<SearchOutlined/>} block onClick={() => {queryData()}}>查询数据量</Button>
                                </Col>

                                <Col span={8} style={{padding: 5}}>
                                    <Button type="primary" icon={<DeleteOutlined/>} block onClick={() => {showDeleteConfirm()}}>删除数据</Button>
                                </Col>

                                <Col span={8} style={{padding: 5}}>
                                    <Button type="primary" icon={<SyncOutlined/>} block onClick={() => {reloadData()}}>更新数据</Button>
                                </Col>
                            </Row>
                        )
                    }
                    , {
                        key: '2',
                        label: `系统设定`,
                        children: ("1111")
                    }
                    , {
                        key: '3',
                        label: `临时`,
                        children: ("aaaa")
                    }
                ]}
            />
        </>
    );
};

export default SysSetting;