import React, {useEffect, useState} from 'react';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import {Tabs, Spin, DatePicker} from 'antd';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';

const sysConst = require('../utils/SysConst');
const commonUtil = require('../utils/CommonUtil');
const dingUtil = require('../utils/DingUtils');
const appAction = require('../utils/AppAction');
const localUtil = require('../utils/LocalUtils');

const CrmStat = () => {
    // 页面调用接口时，是否显示加载中动画
    const [loading, setLoading] = useState(false);
    // 选择年
    const [chooseYear, setChooseYear] = useState(dayjs().add(0,'year'));
    // 集团整体（只统计法人数据）
    const [pageData, setPageData] = useState({tabItems: [], saleTarget: [], saleInfo: [], nicheInfo: []});
    // 权限标记： -1默认值，1：有权限（包含集团经营驾驶舱权限），0：无权限（钉钉中没有经营驾驶舱权限）
    const [roleFlag, setRoleFlag] = useState(-1);

    // 第一次渲染时调用
    useEffect(() => {
        // 使用手机钉钉打开时, 设置标题内容
        dingUtil.setDingTitle('经营数据统计');
        // 取得钉钉登录信息
        setLoading(true);
        appAction.loginDingTalk(
            (userInfo) => {
                // 用户角色
                let roles = userInfo.roleList;
                // 有权限时，再调用API接口 取得销售统计数据
                if (userInfo.userId = '232768324719977641' || (roles != null && roles !== '' && roles.indexOf('集团经营驾驶舱:集团经营驾驶舱') > -1)) {
                    getSaleInfoStat('2024');
                    setRoleFlag(1);
                } else {
                    setRoleFlag(0);
                }
            }
        );
        setLoading(false);
    }, []);

    // 图表1：外部订单目标额
    const getSaleTargetOpt = (title, subtext, data) => {
        return {
            title: {text: title, subtext: subtext + '亿', x: 'center', subtextStyle: {fontSize: 14,fontWeight: 'bold'}},
            tooltip: {trigger: 'item', formatter: '{b}: {c} 亿 ({d}%)'},
            legend: {orient: 'vertical', left: 'left'},
            series: [{name: '', type: 'pie', radius: '50%', data: data}]
        };
    };

    // 图表2：销售信息
    // color：调色盘颜色列表。如果系列没有设置颜色，则会依次循环从该列表中取颜色作为系列颜色。 默认为：
    // color: ['red', 'green','yellow','blue','purple'],
    // ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
    const getSaleInfoOpt = (optData) => {
        // 单位数值 转为亿，并保留2位小数
        let seriesSale = [];
        let seriesWin = [];
        let seriesLeft = [];
        // if (optData.orgName === '天津兆和') {
        //     // 兆和数字 08时，计数单位 按照万
        //     seriesSale = optData.seriesSale.map((item)=>{return (item / 10000).toFixed(2)});
        //     seriesWin = optData.seriesWin.map((item)=>{return (item / 10000).toFixed(2)});
        //     seriesLeft = optData.seriesLeft.map((item)=>{return (item / 10000).toFixed(2)});
        // } else {
        //     seriesSale = optData.seriesSale.map((item)=>{return (item / 100000000).toFixed(2)});
        //     seriesWin = optData.seriesWin.map((item)=>{return (item / 100000000).toFixed(2)});
        //     seriesLeft = optData.seriesLeft.map((item)=>{return (item / 100000000).toFixed(2)});
        // }

        seriesSale = optData.seriesSale.map((item)=>{return (item / 100000000).toFixed(2)});
        seriesWin = optData.seriesWin.map((item)=>{return (item / 100000000).toFixed(2)});
        seriesLeft = optData.seriesLeft.map((item)=>{return (item / 100000000).toFixed(2)});

        return {
            title: {text: '销售信息',subtext: optData.subtext,x: 'center', subtextStyle: {fontSize: 13,fontWeight: 'bold'}},
            tooltip: {
                trigger: 'axis',
                extraCssText: 'height:auto;',
                formatter: function (params) {
                    //params[0].name表示x轴数据（公司名称），params[0].value表示销售数值
                    let percent = 0;
                    percent = params[2].value == 0 ? 100 : commonUtil.percentage(parseFloat(params[0].value) + parseFloat(params[1].value), parseFloat(params[0].value) + parseFloat(params[1].value) + parseFloat(params[2].value));
                    let str = params[0].name + '（完成率 ' + percent + '%）' + '<br/>';
                    // params是数组格式
                    // params.forEach((item, index) => {
                    //     str += "<span style='display:inline-block;width:10px;height:10px;border-radius:10px;background-color:"
                    //         + item.color + ";'></span>" + "\t" + item.seriesName + " : " + (optData.seriesSale[index]).toFixed(2) + '<br/>'
                    // });
                    for (let item of params) {
                        str += "<span style='display:inline-block;width:10px;height:10px;border-radius:10px;background-color:"
                            + item.color + ";'></span>" + "\t" + item.seriesName + " : " + item.value + '<br/>'
                    }
                    return str
                },
            },
            color: ['#5470c6','#fac858','#91cc75'],
            legend: {orient: 'vertical',left: 'left'},
            grid: {top: '25%',left: '3%',right: '4%',bottom: '3%',containLabel: true},
            xAxis: {type: 'value'},
            yAxis: {type: 'category',data: optData.yAxisData},
            series: [
                {name: '销售额', type: 'bar', stack: 'total', label: {show: true}, data: seriesSale},
                {name: '中标未签', type: 'bar', stack: 'total', label: {show: true}, data: seriesWin},
                {name: '未完成额', type: 'bar', stack: 'total', label: {show: true}, data: seriesLeft}
            ]
        }
    };

    // 图表3：商机
    const getNicheInfoOpt = (optData) => {
        // 单位数值 转为亿，并保留2位小数
        let seriesNiche = [];
        let seriesKeyNiche = [];
        let seriesLeft = [];

        // // 集团00 时，去掉 最后一个组织 兆和数字的值
        // if (optData.orgNum === '00') {
        //     seriesNiche = optData.seriesNiche.map((item)=>{return (item / 100000000).toFixed(2)});
        //     seriesKeyNiche = optData.seriesKeyNiche.map((item)=>{return (item / 100000000).toFixed(2)});
        //     seriesLeft = optData.seriesLeft.map((item)=>{return (item / 100000000).toFixed(2)});
        //     seriesNiche.splice(seriesNiche.length-1);
        //     seriesKeyNiche.splice(seriesKeyNiche.length-1);
        //     seriesLeft.splice(seriesLeft.length-1);
        //     optData.yAxisData.splice(5);
        // } else if (optData.orgNum === '08') {
        //     // 兆和数字 08时，计数单位 按照万
        //     seriesNiche = optData.seriesNiche.map((item)=>{return (item / 10000).toFixed(2)});
        //     seriesKeyNiche = optData.seriesKeyNiche.map((item)=>{return (item / 10000).toFixed(2)});
        //     seriesLeft = optData.seriesLeft.map((item)=>{return (item / 10000).toFixed(2)});
        // } else {
        //     seriesNiche = optData.seriesNiche.map((item)=>{return (item / 100000000).toFixed(2)});
        //     seriesKeyNiche = optData.seriesKeyNiche.map((item)=>{return (item / 100000000).toFixed(2)});
        //     seriesLeft = optData.seriesLeft.map((item)=>{return (item / 100000000).toFixed(2)});
        // }

        seriesNiche = optData.seriesNiche.map((item)=>{return (item / 100000000).toFixed(2)});
        seriesKeyNiche = optData.seriesKeyNiche.map((item)=>{return (item / 100000000).toFixed(2)});
        seriesLeft = optData.seriesLeft.map((item)=>{return (item / 100000000).toFixed(2)});

        return {
            title: {text: '商机信息',subtext: optData.subtext,x: 'center',subtextStyle: {fontSize: 13,fontWeight: 'bold',color: optData.subtextColor}},
            tooltip: {
                trigger: 'axis',
                extraCssText: 'height:auto;',
                formatter: function (params) {
                    //params[0].name表示x轴数据（公司名称），params[0].value表示销售数值
                    let multiples = params[2].value == 0 ? '0.00' : (params[0].value / params[2].value).toFixed(2);
                    let str = params[0].name
                        + '（商机倍数 <span style="color:' + (multiples > 3 ? 'green' : (multiples < 1 ? 'red' : 'orange')) + '">' + multiples
                        + '</span>）' + '<br/>';
                    //params是数组格式
                    for (let item of params) {
                        str += "<span style='display:inline-block;width:10px;height:10px;border-radius:10px;background-color:"
                            + item.color + ";'></span>" + "\t" + item.seriesName + " : " + item.value + '<br/>'
                    }
                    return str
                },
            },
            color: ['#5470c6','#ee6666','#91cc75'],
            legend: {orient: 'vertical',left: 'left'},
            grid: {top: '25%',left: '3%',right: '4%',bottom: '3%',containLabel: true},
            xAxis: {type: 'value'},
            yAxis: {type: 'category',data: optData.yAxisData},
            series: [
                {name: '商机金额',type: 'bar',label: {show: true},data: seriesNiche},
                {name: '重点商机',type: 'bar',label: {show: true},data: seriesKeyNiche},
                {name: '未完成额',type: 'bar',label: {show: true},data: seriesLeft}
            ]
        }
    };

    // 图表4：回款信息
    const getReturnInfoOpt = (optData) => {
        // 单位数值 转为亿，并保留2位小数
        let seriesNiche = [];
        let seriesKeyNiche = [];
        let seriesLeft = [];

        seriesNiche = optData.seriesNiche.map((item)=>{return (item / 100000000).toFixed(2)});
        seriesKeyNiche = optData.seriesKeyNiche.map((item)=>{return (item / 100000000).toFixed(2)});
        seriesLeft = optData.seriesLeft.map((item)=>{return (item / 100000000).toFixed(2)});

        return {
            title: {text: '回款信息',subtext: optData.subtext,x: 'center',subtextStyle: {fontSize: 13,fontWeight: 'bold',color: optData.subtextColor}},
            tooltip: {
                trigger: 'axis',
                extraCssText: 'height:auto;'
            },
            color: ['#5470c6','#ee6666','#91cc75'],
            legend: {orient: 'vertical',left: 'left'},
            grid: {top: '25%',left: '3%',right: '4%',bottom: '3%',containLabel: true},
            xAxis: {type: 'value'},
            yAxis: {type: 'category',data: optData.yAxisData},
            series: [
                {name: '回款金额',type: 'bar',label: {show: true},data: seriesNiche},
            ]
        }
    };

    /********************************** 核心代码：查询接口返回数据后，组装成页面需要格式 **********************************/

    // 调用后端WebApi，取得金蝶销售订单以及CRM商机 统计数据
    const getSaleInfoStat = (year) => {
        // 加载中动画
        setLoading(true);
        // 本年的日期范围
        // let dateRangeParam = commonUtil.getDateRange("本年");
        // 根据条件 调用后端接口
        axios.post(sysConst.DOMAIN + "/crm/niche/query", {
            year: year
        })
            .then(res => {
                console.log('res data : ',res.data);

                // 固定值：销售订单目标 : 常量
                let saleTarget = {};
                // 当前年 若是2023年的话，将2023年订单目标赋值
                if (year === '2024') {
                    saleTarget = sysConst.SALE_TARGET_2024;
                }

                // 返回结果中的 success 为 true
                if (res.data.success) {
                    // 遍历 外部销售订单
                    res.data.out.forEach((currentValue, index) => {
                        // 根据 查询到的数据 组织 组装页面数据
                        switch (currentValue.ORG_NUM) {
                            case "0102":
                                // 兆和空气(外部订单)
                                saleTarget.tab[0].child[0].saleAmt = saleTarget.tab[0].child[0].saleAmt + parseFloat(currentValue.AMT);
                                // 环保机电
                                saleTarget.tab[1].child[0].saleAmt = parseFloat(currentValue.AMT);
                                break;
                            case "0103":
                                // 兆和空气(外部订单)
                                saleTarget.tab[0].child[0].saleAmt = saleTarget.tab[0].child[0].saleAmt + parseFloat(currentValue.AMT);
                                // voc事业部 / 有机废气
                                saleTarget.tab[1].child[1].saleAmt = parseFloat(currentValue.AMT);
                                break;
                            case "0105":
                            case "0502":
                                // 兆和空气(外部订单)
                                saleTarget.tab[0].child[0].saleAmt = saleTarget.tab[0].child[0].saleAmt + parseFloat(currentValue.AMT);
                                // 除尘系统事业部
                                saleTarget.tab[1].child[2].saleAmt = saleTarget.tab[1].child[2].saleAmt + parseFloat(currentValue.AMT);
                                break;
                            case "04":
                                // 天津兆和
                                saleTarget.tab[0].child[1].saleAmt = parseFloat(currentValue.AMT);
                                saleTarget.tab[2].child[0].saleAmt = parseFloat(currentValue.AMT);
                                break;
                            case "0602":
                            case "0604":
                                // 苏州环能(外部订单)
                                saleTarget.tab[0].child[2].saleAmt = saleTarget.tab[0].child[2].saleAmt + parseFloat(currentValue.AMT);
                                // 除尘系统事业部
                                saleTarget.tab[3].child[0].saleAmt = saleTarget.tab[3].child[0].saleAmt + parseFloat(currentValue.AMT);
                                break;
                            case "0603":
                                // 苏州环能(外部订单)
                                saleTarget.tab[0].child[2].saleAmt = saleTarget.tab[0].child[2].saleAmt + parseFloat(currentValue.AMT);
                                // 除尘系统事业部
                                saleTarget.tab[3].child[1].saleAmt = saleTarget.tab[3].child[1].saleAmt + parseFloat(currentValue.AMT);
                                break;
                            case "07":
                                // 上海兆和
                                saleTarget.tab[0].child[3].saleAmt = parseFloat(currentValue.AMT);
                                saleTarget.tab[4].child[0].saleAmt = parseFloat(currentValue.AMT);
                                break;
                            default :
                                break;
                        }
                    });

                    // 遍历 已中标未签单
                    res.data.win.forEach((currentValue, index) => {
                        switch (currentValue.orgNo) {
                            case "0102":
                                // 兆和空气(外部订单)
                                saleTarget.tab[0].child[0].winAmt = saleTarget.tab[0].child[0].winAmt + parseFloat(currentValue.amt);
                                // 环保机电
                                saleTarget.tab[1].child[0].winAmt = parseFloat(currentValue.amt);
                                break;
                            case "0103":
                                // 兆和空气(外部订单)
                                saleTarget.tab[0].child[0].winAmt = saleTarget.tab[0].child[0].winAmt + parseFloat(currentValue.amt);
                                // voc事业部 / 有机废气
                                saleTarget.tab[1].child[1].winAmt = parseFloat(currentValue.amt);
                                break;
                            case "0105":
                            case "0502":
                                // 兆和空气(外部订单)
                                saleTarget.tab[0].child[0].winAmt = saleTarget.tab[0].child[0].winAmt + parseFloat(currentValue.amt);
                                // 除尘系统事业部
                                saleTarget.tab[1].child[2].winAmt = saleTarget.tab[1].child[2].winAmt + parseFloat(currentValue.amt);
                                break;
                            case "04":
                                // 天津兆和
                                saleTarget.tab[0].child[1].winAmt = parseFloat(currentValue.amt);
                                saleTarget.tab[2].child[0].winAmt = parseFloat(currentValue.amt);
                                break;
                            case "0602":
                            case "0604":
                                // 苏州环能(外部订单)
                                saleTarget.tab[0].child[2].winAmt = saleTarget.tab[0].child[2].winAmt + parseFloat(currentValue.amt);
                                // 除尘系统事业部
                                saleTarget.tab[3].child[0].winAmt = saleTarget.tab[3].child[0].winAmt + parseFloat(currentValue.amt);
                                break;
                            case "0603":
                                // 苏州环能(外部订单)
                                saleTarget.tab[0].child[2].winAmt = saleTarget.tab[0].child[2].winAmt + parseFloat(currentValue.amt);
                                // 除尘系统事业部
                                saleTarget.tab[3].child[1].winAmt = saleTarget.tab[3].child[1].winAmt + parseFloat(currentValue.amt);
                                break;
                            case "07":
                                // 上海兆和
                                saleTarget.tab[0].child[3].winAmt = parseFloat(currentValue.amt);
                                saleTarget.tab[4].child[0].winAmt = parseFloat(currentValue.amt);
                                break;
                            default :
                                break;
                        }
                    });

                    // 遍历 CRM 结果集
                    res.data.crm.forEach((currentValue) => {
                        switch (currentValue.orgNo) {
                            case "0102":
                                // 兆和空气(外部订单)
                                saleTarget.tab[0].child[0].nicheAmt = saleTarget.tab[0].child[0].nicheAmt + parseFloat(currentValue.amt);
                                saleTarget.tab[0].child[0].keyNicheAmt = saleTarget.tab[0].child[0].keyNicheAmt + parseFloat(currentValue.keyPoint);
                                // 环保机电
                                saleTarget.tab[1].child[0].nicheAmt = parseFloat(currentValue.amt);
                                saleTarget.tab[1].child[0].keyNicheAmt = parseFloat(currentValue.keyPoint);
                                break;
                            case "0103":
                                // 兆和空气(外部订单)
                                saleTarget.tab[0].child[0].nicheAmt = saleTarget.tab[0].child[0].nicheAmt + parseFloat(currentValue.amt);
                                saleTarget.tab[0].child[0].keyNicheAmt = saleTarget.tab[0].child[0].keyNicheAmt + parseFloat(currentValue.keyPoint);
                                // voc事业部 / 有机废气
                                saleTarget.tab[1].child[1].nicheAmt = parseFloat(currentValue.amt);
                                saleTarget.tab[1].child[1].keyNicheAmt = parseFloat(currentValue.keyPoint);
                                break;
                            case "0105":
                            case "0502":
                                // 兆和空气(外部订单)
                                saleTarget.tab[0].child[0].nicheAmt = saleTarget.tab[0].child[0].nicheAmt + parseFloat(currentValue.amt);
                                saleTarget.tab[0].child[0].keyNicheAmt = saleTarget.tab[0].child[0].keyNicheAmt + parseFloat(currentValue.keyPoint);
                                // 除尘系统事业部
                                saleTarget.tab[1].child[2].nicheAmt = saleTarget.tab[1].child[2].nicheAmt + parseFloat(currentValue.amt);
                                saleTarget.tab[1].child[2].keyNicheAmt = saleTarget.tab[1].child[2].keyNicheAmt + parseFloat(currentValue.keyPoint);
                                break;
                            case "04":
                                // 天津兆和
                                saleTarget.tab[0].child[1].nicheAmt = parseFloat(currentValue.amt);
                                saleTarget.tab[0].child[1].keyNicheAmt = parseFloat(currentValue.keyPoint);
                                saleTarget.tab[2].child[0].nicheAmt = parseFloat(currentValue.amt);
                                saleTarget.tab[2].child[0].keyNicheAmt = parseFloat(currentValue.keyPoint);
                                break;
                            case "0602":
                            case "0604":
                                // 苏州环能(外部订单)
                                saleTarget.tab[0].child[2].nicheAmt = saleTarget.tab[0].child[2].nicheAmt + parseFloat(currentValue.amt);
                                saleTarget.tab[0].child[2].keyNicheAmt = saleTarget.tab[0].child[2].keyNicheAmt + parseFloat(currentValue.keyPoint);
                                // 除尘系统事业部
                                saleTarget.tab[3].child[0].nicheAmt = saleTarget.tab[3].child[0].nicheAmt + parseFloat(currentValue.amt);
                                saleTarget.tab[3].child[0].keyNicheAmt = saleTarget.tab[3].child[0].keyNicheAmt + parseFloat(currentValue.amt);
                                break;
                            case "0603":
                                // 苏州环能(外部订单)
                                saleTarget.tab[0].child[2].nicheAmt = saleTarget.tab[0].child[2].nicheAmt + parseFloat(currentValue.amt);
                                saleTarget.tab[0].child[2].keyNicheAmt = saleTarget.tab[0].child[2].keyNicheAmt + parseFloat(currentValue.keyPoint);
                                // 除尘系统事业部
                                saleTarget.tab[3].child[1].nicheAmt = saleTarget.tab[3].child[1].nicheAmt + parseFloat(currentValue.amt);
                                saleTarget.tab[3].child[1].keyNicheAmt = saleTarget.tab[3].child[1].keyNicheAmt + parseFloat(currentValue.amt);
                                break;
                            case "07":
                                // 上海兆和
                                saleTarget.tab[0].child[3].nicheAmt = parseFloat(currentValue.amt);
                                saleTarget.tab[0].child[3].keyNicheAmt = parseFloat(currentValue.keyPoint);
                                saleTarget.tab[4].child[0].nicheAmt = parseFloat(currentValue.amt);
                                saleTarget.tab[4].child[0].keyNicheAmt = parseFloat(currentValue.keyPoint);
                                break;
                            default :
                                break;
                        }
                    });

                    // 按照法人循环 计算未完成额 以及法人金额
                    saleTarget.tab.forEach((item, index) => {
                        let fatherSaleAmt = 0;
                        let fatherWinAmt = 0;
                        let fatherNicheAmt = 0;
                        let fatherKeyNicheAmt = 0;
                        item.child.forEach((child, index) => {
                            // 未完成额 = 计划任务 - 销售额 - 中标未签
                            child.leftAmt = child.value * 100000000 - child.saleAmt - child.winAmt;
                            child.leftAmt = child.leftAmt < 0 ? 0 : child.leftAmt.toFixed(2);
                            fatherSaleAmt = fatherSaleAmt + child.saleAmt;
                            fatherWinAmt = fatherWinAmt + child.winAmt;
                            fatherNicheAmt = fatherNicheAmt + child.nicheAmt;
                            fatherKeyNicheAmt = fatherKeyNicheAmt + child.keyNicheAmt;
                        });
                        item.saleAmt = fatherSaleAmt;
                        item.winAmt = fatherWinAmt;
                        item.nicheAmt = fatherNicheAmt;
                        item.keyNicheAmt = fatherKeyNicheAmt;
                        // 未完成额 = 计划任务 - 销售额 - 中标未签
                        item.leftAmt = item.value * 100000000 - item.saleAmt - item.winAmt;
                        item.leftAmt = item.leftAmt < 0 ? 0 : item.leftAmt.toFixed(2);
                    });

                    // 页面 Tab标签 动态内容
                    let tabItems = [];
                    // 遍历 常量类中的 年度目标 TODO
                    saleTarget.tab.forEach((item, index) => {
                        // 上部 订单目标 饼图
                        let targetPie = getSaleTargetOpt('订单目标额', item.value, item.child);

                        // 中部 销售信息 柱状图
                        let saleAmt = [];
                        let winAmt = [];
                        let leftAmt = [];
                        let nicheAmt = [];
                        let keyNicheAmt = [];
                        // 遍历孩子节点
                        item.child.forEach((child, index) => {
                            saleAmt.push(child.saleAmt);
                            winAmt.push(child.winAmt);
                            leftAmt.push(child.leftAmt);
                            nicheAmt.push(child.nicheAmt);
                            keyNicheAmt.push(child.keyNicheAmt);
                        });

                        // 子标题 （ 销售额，完成率）
                        let subText = (item.saleAmt/100000000).toFixed(2) + '亿（完成率 '
                            + commonUtil.percentage(item.saleAmt, item.value * 100000000)
                            + '%）';

                        let optData = {
                            subtext : subText,
                            yAxisData : item.child.map((item)=>{return item.name}),
                            seriesSale : saleAmt,
                            seriesWin : winAmt,
                            seriesLeft : leftAmt,
                            orgName: item.name
                        };
                        let saleInfo = getSaleInfoOpt(optData);

                        // 底部 商机信息 柱状图
                        let nicheMul = item.leftAmt <= 0 ? 0 : item.nicheAmt / item.leftAmt;
                        let optNicheData = {
                            subtext : (item.nicheAmt/100000000).toFixed(2) + '亿（商机倍数 ' + nicheMul.toFixed(2) + '）',
                            subtextColor: nicheMul > 3 ? 'green' : (nicheMul < 1 ? 'red' : 'orange'),
                            yAxisData : item.child.map((current)=>{return current.name}),
                            seriesNiche : nicheAmt,
                            seriesKeyNiche : keyNicheAmt,
                            seriesLeft : leftAmt,
                            orgName: item.name
                        };
                        let nicheInfo = getNicheInfoOpt(optNicheData);

                        // 4. 回款信息
                        let returnInfo = getReturnInfoOpt(optNicheData);

                        tabItems.push({
                            key: index + 1,
                            label: item.name,
                            children: (
                                <>
                                    {/* 订单目标饼图 */}
                                    <ReactECharts style={{height: 320}} option={targetPie}/>
                                    <ReactECharts style={{marginTop: 0}} option={saleInfo}/>
                                    <ReactECharts style={{marginTop: 15}} option={nicheInfo}/>
                                    {/*<ReactECharts style={{marginTop: 15}} option={returnInfo}/>*/}
                                </>
                            )
                        });
                    });

                    // 赋值 tab页签
                    setPageData({...pageData, tabItems: tabItems});
                } else {
                    if (!res.data.success) {
                        // res.data.message=会话信息已丢失，请重新登录
                        // appAction.loginKd();
                        alert("调用金蝶API异常，请尝试刷新页面！");
                    } else {
                        alert(`getSaleInfoStat no data`)
                    }
                }
                setLoading(false);
            }).catch(error => {
            alert('getSaleInfoStat error');
            console.log("getSaleInfoStat err, " + JSON.stringify(error));
            setLoading(false);
        })
    };

    const changeTab = (key) => {};

    const onChartClick = (e) => {
        // console.log("onChartClick");
        // setOptionData(option2);
    };

    const onChartLegendselectchanged = (e) => {
        // console.log("onChartLegendselectchanged");
        // console.log(e);
    };

    const onEvents = {
        'click': onChartClick,
        'legendselectchanged': onChartLegendselectchanged
    };

    const disabledDate = (current) => {
        // 2024年之前 和 未来月份不能选额
        return current < dayjs('2024-01-01') || dayjs().add(0,'year') < current;
    };

    const changeYear = (date, dateString) => {
        if (dateString !== '2024'&& dateString !== '') {
            alert("暂无选择年份的目标，请联系相关责任人确认！");
            return;
        }
        if (date != null) {
            setChooseYear(date);
            getSaleInfoStat(dateString);
        }
    };

    return (
        <>
            {/* 加载中动画，居中显示 */}
            <div style={{zIndex: 999, position: 'absolute', top: '40%', left: '46%'}}>
                <Spin size="large" spinning={loading}/>
            </div>

            {/*{roleFlag === -1 &&*/}
            {/*<div style={{zIndex: 999, position: 'absolute', top: '40%', left: '35%'}}>*/}
            {/*    <span>查询钉钉权限中...</span>*/}
            {/*</div>}*/}

            {roleFlag === 0 &&
            <div style={{zIndex: 999, position: 'absolute', top: '40%', left: '12%'}}>
                <span>您没有查看此内容权限，请与管理人员联系！</span>
            </div>}

            <div>
                &nbsp;年份：<DatePicker picker="year" bordered={false} disabledDate={disabledDate}
                            value={chooseYear}
                            onChange={(date,dateString) => {changeYear(date,dateString)}}/>
            </div>

            {roleFlag === 1 &&
            <Tabs
                defaultActiveKey="1"
                onChange={changeTab}
                tabBarStyle={{paddingLeft:5, position: 'sticky', top: 0, zIndex : 999, backgroundColor: 'white'}}
                items={pageData.tabItems}
                // items={
                //
                //
                //     [
                //     {
                //         key: '1', label: `兆和集团`,
                //         children: (
                //             <>
                //                 <ReactECharts style={{height: 320}} option={companyInfo.saleTarget}/>
                //                 <ReactECharts style={{marginTop: 0}} option={companyInfo.saleInfo}/>
                //                 <ReactECharts style={{marginTop: 15}} option={companyInfo.nicheInfo}/>
                //             </>
                //         )
                //     }
                //     , {
                //         key: '2', label: `兆和空气`,
                //         children: (
                //             <>
                //                 <ReactECharts style={{height: 320}} option={comp01Info.saleTarget}/>
                //                 <ReactECharts style={{marginTop: 0}} option={comp01Info.saleInfo}/>
                //                 <ReactECharts style={{marginTop: 15}} option={comp01Info.nicheInfo}/>
                //             </>
                //         )
                //     }
                //     , {
                //         key: '3', label: `天津兆和`,
                //         children: (
                //             <>
                //                 <ReactECharts style={{height: 320}} option={comp04Info.saleTarget}/>
                //                 <ReactECharts style={{marginTop: 0}} option={comp04Info.saleInfo}/>
                //                 <ReactECharts style={{marginTop: 15}} option={comp04Info.nicheInfo}/>
                //             </>
                //         )
                //     }
                //     , {
                //         key: '4', label: `苏州智能`,disabled: dayjs(chooseYear).year() != '2023',
                //         children: (
                //             <>
                //
                //                 <ReactECharts style={{height: 320}} option={comp05Info.saleTarget}/>
                //                 <ReactECharts style={{marginTop: 0}} option={comp05Info.saleInfo}/>
                //                 <ReactECharts style={{marginTop: 15}} option={comp05Info.nicheInfo}/>
                //             </>
                //         )
                //     }
                //     , {
                //         key: '5', label: `苏州环能`,
                //         children: (
                //             <>
                //                 <ReactECharts style={{height: 320}} option={comp06Info.saleTarget}/>
                //                 <ReactECharts style={{marginTop: 0}} option={comp06Info.saleInfo}/>
                //                 <ReactECharts style={{marginTop: 15}} option={comp06Info.nicheInfo}/>
                //             </>
                //         )
                //     }
                //     , {
                //         key: '6', label: `上海兆和`,
                //         children: (
                //             <>
                //                 <ReactECharts style={{height: 320}} option={comp07Info.saleTarget}/>
                //                 <ReactECharts style={{marginTop: 0}} option={comp07Info.saleInfo}/>
                //                 <ReactECharts style={{marginTop: 15}} option={comp07Info.nicheInfo}/>
                //             </>
                //         )
                //     }
                //     , {
                //         key: '7', label: `兆和数字`,
                //         children: (
                //             <>
                //                 <ReactECharts style={{height: 320}} option={comp08Info.saleTarget}/>
                //                 <ReactECharts style={{marginTop: 0}} option={comp08Info.saleInfo}/>
                //                 <ReactECharts style={{marginTop: 15}} option={comp08Info.nicheInfo}/>
                //             </>
                //         )
                //     }
                // ]}
            />}

            {/*/!* 图表 *!/*/}
            {/*<ReactECharts style={{marginTop: 115}} option={optionData} onEvents={{*/}
            {/*    'click': onChartClick,*/}
            {/*    'legendselectchanged': onChartLegendselectchanged*/}
            {/*}}/>*/}
        </>
    );
};

export default CrmStat;