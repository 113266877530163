import React from 'react';
import {Table} from 'antd';
import 'antd/dist/antd.css';

const AprTable = (props) => {
    return (
        <Table bordered scroll={{x: '100%', y: 'calc(100vh - 298px)'}}
               pagination={false} //分页
               columns={props.columns}
               dataSource={props.data.dataList}
               summary={() => (
                   <Table.Summary fixed>
                       <Table.Summary.Row>
                           <Table.Summary.Cell index={0} align="center">总计</Table.Summary.Cell>
                           <Table.Summary.Cell index={1} align="center">{props.data.userCnt}人</Table.Summary.Cell>
                           <Table.Summary.Cell index={2} align="center">{props.data.billCnt}</Table.Summary.Cell>
                           <Table.Summary.Cell index={3} align="center">{props.data.hours}</Table.Summary.Cell>
                       </Table.Summary.Row>
                   </Table.Summary>
               )}
        />
    );
};

export default AprTable;