import React from 'react';
import {Col, Row, Drawer, Button} from 'antd';
import 'antd/dist/antd.css';

const sysConst = require('../utils/SysConst');

const DateRangeDrawer = (props) => {
    // 日期范围 选择框
    let range = [];
    if (props.range === 'all') {
        // 包含可以选择任意月份
        range = sysConst.DATE_RANGE_N;
    } else {
        // 固定日期范围列表
        range = sysConst.DATE_RANGE;
    }

    return (
        <Drawer title={<div style={{width: "85%", textAlign: "center"}}>选择日期</div>} placement="bottom"
                onClose={() => {props.setOpen(false)}} open={props.timeOpen}>
            <Row>
                {range.map(function (item, index) {
                    return (
                        <Col span={12} style={{padding: 10}} key={index}>
                            <Button block onClick={() => {
                                props.changeTime(item)
                            }}>
                                {item.label}
                            </Button>
                        </Col>
                    )
                })}
            </Row>
        </Drawer>
    );
};

export default DateRangeDrawer;