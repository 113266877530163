import React, {useEffect, useState} from 'react'
import {Button, Spin, Tabs, Table, Input, Row, Col} from 'antd';
import axios from "axios";

const sysConst = require('../utils/SysConst');
const dingUtil = require('../utils/DingUtils');
const appAction = require('../utils/AppAction');
const localUtil = require('../utils/LocalUtils');

// 兆和施工日志统计表格
const PmBuildLog = (props) => {
    // 页面调用接口时，是否显示加载中动画
    const [loading, setLoading] = useState(false);
    // 检索条件：项目经理，项目名称
    const [condition, setCondition] = useState({prjManager:"",prjName:""});
    // TAB标签 默认索引
    // const [tabIndex, setTabIndex] = useState("1");
    // 列表数据
    const [buildLogDataArr, setBuildLogDataArr] = useState([]);

    // 第一次渲染时调用
    useEffect(() => {
        // 使用手机钉钉打开时, 设置标题内容
        dingUtil.setDingTitle('兆和施工日志统计');
        getPmData("", "");
    }, []);

    // table 固定表头
    const columns = [
        {title: 'No.', fixed: true, width: 56, align: 'center', dataIndex: 'key'},
        {title: '项目经理', fixed: true, width: 89, align: 'center', dataIndex: '项目经理'},
        {title: '项目名称', width: 176, align: 'center', dataIndex: '项目名称'},
        {
            title: '本周',
            width: 76,
            align: 'center',
            dataIndex: '周填报数量',
            // 取消默认排序，只能升序，降序 来回切换
            // sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.周填报数量 - b.周填报数量
        },
        {
            title: '本月',
            width: 76,
            align: 'center',
            dataIndex: '月填报数量',
            sorter: (a, b) => a.月填报数量 - b.月填报数量
        },
        {
            title: '本季',
            width: 76,
            align: 'center',
            dataIndex: '季度填报数量',
            sorter: (a, b) => a.季度填报数量 - b.季度填报数量
        }
    ];

    // 调用后端WebApi，取得PM 兆和施工日志统计数据
    const getBuildLogData = async (prjManager, prjName) => {
        // 加载中
        setLoading(true);
        // 初始化数据
        setBuildLogDataArr([]);
        // 调用PM接口
        axios.post(sysConst.DOMAIN + "/pm/construction/log", {
            prjManager: prjManager, prjName: prjName
        }).then(res => {
            if (res.status === 200 && res.data.success) {
                // 数组序号 on ready state change
                let arr = [];
                res.data.rows.forEach((currentValue, index) => {
                    arr.push({...currentValue, key: index + 1})
                });
                setBuildLogDataArr(arr);
            } else {
                alert('getPmTemp error');
                console.log("getPmTemp err, ");
            }
            setLoading(false);
        }).catch(error => {
            alert('getPmTemp error');
            console.log("getPmTemp err, " + JSON.stringify(error));
            setLoading(false);
        })
    };

    const getPmData = (prjManager, prjName) => {
        // 取得钉钉登录信息
        appAction.loginDingTalk(
            () => {
                // 显示数据
                // TODO 这里取得钉钉用户名或者ID或者角色啥的 以后用来区分 显示数据()
                console.log('DING_USER_NM',localUtil.getSessionItem(sysConst.DING_USER_NM));
                // 调用金蝶接口 取得数据
                getBuildLogData(prjManager, prjName);
            }
        );
    };

    const changeTab = (key) => {};

    const onTabClick = (key) => {};

    // 查询
    const queryData = () => {
        getPmData(condition.prjManager, condition.prjName);
    };

    return (
        <>
            {/* 加载中 动画，居中显示 */}
            <div style={{zIndex: 999,position:'absolute',top:'40%',left:'46%'}}>
                <Spin size="large" spinning={loading}/>
            </div>

            <Row style={{padding: 5}}>
                <Col span={6} style={{marginTop: 5}}>项目经理：</Col>
                <Col span={18} style={{marginTop: 5}}>
                    <Input size="small" placeholder="请输入项目经理" value={condition.prjManager}
                           onChange={(e) => {
                               setCondition({...condition, prjManager: e.target.value})
                           }}/>
                </Col>

                <Col span={6} style={{marginTop: 5}}>项目名称：</Col>
                <Col span={18} style={{marginTop: 5}}>
                    <Input size="small" placeholder="请输入项目名称" value={condition.prjName}
                           onChange={(e) => {
                               setCondition({...condition, prjName: e.target.value})
                           }}/>
                </Col>
                {/* 刷新图标 */}
                <Col span={24} style={{textAlign: "right", marginTop: 5}}>
                    <Button block onClick={() => {queryData()}}>查询</Button>
                </Col>
            </Row>

            <div style={{paddingLeft: 5, paddingRight: 5}}>
                <Tabs
                    activeKey={'1'}
                    onChange={changeTab}
                    onTabClick={onTabClick}
                    items={[
                        {key: '1',label: `兆和施工日志统计`,children: (
                                <Table bordered scroll={{x: '100%', y: 'calc(100vh - 230px)'}}
                                       pagination={false} //分页
                                       columns={columns}
                                       dataSource={buildLogDataArr}
                                />
                            )},
                    ]}
                />
            </div>
        </>
    )
};

export default PmBuildLog;