import * as dd from "dingtalk-jsapi";

const sysConst = require('../utils/SysConst');

/** 利用 钉钉开放平台提供的jsApi 包装部分接口 */

// 钉钉 获取免登授权码
export function getCode(callback) {
	if (dd.env.platform !== "notInDingTalk") {
		dd.ready(() => {
			//使用SDK 获取免登授权码
			dd.runtime.permission.requestAuthCode({
				// 公司的corpId
				corpId: sysConst.CORP_ID,
				onSuccess: (info) => {
					// 根据钉钉提供的api 获得code后,再次调用这个callback方法
					// 由于是钉钉获取code是异步操作,不知道什么时候执行完毕
					// callback 函数会等他执行完毕后在自己调用自己
					callback(info.code)
				},
				onFail: (err) => {
					alert('fail');
					alert(JSON.stringify(err));
					console.log("get corpId code failed --->" + JSON.stringify(err));
				},
			});
		});
	} else {
		alert("Please Use DingTalk Environment！");
	}
}

export const setDingTitle = (titleMsg) => {
	// 使用手机钉钉打开时, 设置标题内容
	if (browserNavigator.versions.DingTalk) {
		dd.biz.navigation.setTitle({title: titleMsg})
	}
};

export const dToast = (message) => {
	dd.device.notification.toast({text:message});

	// dd.device.notification.toast({
	// 	icon: '', //icon样式，不同客户端参数不同，请参考参数说明
	// 	text: String, //提示信息
	// 	duration: Number, //显示持续时间，单位秒，默认按系统规范[android只有两种(<=2s >2s)]
	// 	delay: Number, //延迟显示，单位秒，默认0
	// 	onSuccess : function(result) {
	// 		/*{}*/
	// 	},
	// 	onFail : function(err) {}
	// })
};

export const dAlert = (message) => {
    dd.device.notification.alert({
        message: message,
        title: "提示", //可传空
        buttonName: "确定",
        onSuccess : function() {
            //onSuccess将在点击button之后回调
            /*回调*/
        },
        onFail : function(err) {
            //
        }
    });
};

export const openLink = (url) => {
    dd.biz.util.openLink({
        url: url,
        onSuccess: function() {},
        onFail: function() {}
    });
};

export const openSlidePanel = (url, title='title') => {
	dd.biz.util.openSlidePanel({
		// url: 'https://desktop.xfannix.com/index.html#' + url,
		url: url,
		title,
		onSuccess: function() {},
        onFail: function() {}
	})
};

export const browserNavigator = {
	versions: (() => {
		let u = navigator.userAgent;
		return {
            trident: u.indexOf('Trident') > -1, //IE内核
			presto: u.indexOf('Presto') > -1, //opera内核
			webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
			gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, //火狐内核
			mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
			ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
			android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
			iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
			iPad: u.indexOf('iPad') > -1, //是否iPad
			Safari: u.indexOf('Safari') === -1, //是否webApp程序，没有头部与底部
			DingTalk: u.toLowerCase().indexOf('dingtalk') > -1,// 钉钉浏览器
			Windows: u.toLowerCase().indexOf('windows') > -1,
		}
	})()
};