import React from 'react';
import {Col, Row, Popover} from 'antd';
import 'antd/dist/antd.css';
import {QuestionCircleOutlined} from '@ant-design/icons';

const ManuCard = (props) => {
    // 页面样式
    const styles = {
        body: {borderRadius: 5, padding: 5, background: props.bk},
        topl: {paddingLeft: 1, paddingBottom:1, textAlign: props.dat.textAlign},
        botl: {paddingLeft: 2}
    };

    const popoverContent = (
        <div style={{fontSize:10}}>
            <Col span={24}>{props.dat.ruleT}</Col>
            <Col span={24}>{props.dat.ruleC}</Col>
            <Col span={24}>{props.dat.ruleB}</Col>
            <Col span={24}>{props.dat.ruleTh}</Col>
        </div>
    );

    return (
        <Row style={styles.body}>
            <Col span={24} style={styles.topl}>
                <span style={{fontSize: 12}}>
                    <Popover content={popoverContent}>
                        {props.msg} <QuestionCircleOutlined style={{fontSize: 11}}/>：{props.dat.main}
                    </Popover>
                </span>
            </Col>

            <Col span={24} style={styles.botl}>
                <span style={{fontSize: 11}}>{props.dat.left}</span>
            </Col>

            {props.pad &&
                <>
                    <Col span={24} style={{paddingLeft: 2, height: 22}}>
                        <span style={{fontSize: 11}}>{props.dat.right}</span>
                    </Col>
                    {props.dat.third != undefined &&
                    <Col span={24} style={{paddingLeft: 2, height: 22}}>
                        <span style={{fontSize: 11}}>{props.dat.third}</span>
                    </Col>}
                </>}

            {!props.pad &&
                <>
                    <Col span={24} style={styles.botl}>
                        <span style={{fontSize: 11}}>{props.dat.right}</span>
                    </Col>
                    {props.dat.third != undefined &&
                    <Col span={24} style={{paddingLeft: 2, height: 22}}>
                        <span style={{fontSize: 11}}>{props.dat.third}</span>
                    </Col>}
                </>}
        </Row>
    );
};

export default ManuCard;