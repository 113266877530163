import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import qs from "querystring";
import {Col, Row, Tabs, Spin, Button, Input, Space, Table, Popover} from 'antd';
import 'antd/dist/antd.css';
import {SearchOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ManuCard from "../component/ManuCard";
import ManuListCard from "../component/ManuListCard";
import DateRangeDrawer from "../component/DateRangeDrawer";
import ManuOrgListDrawer from "../component/ManuOrgListDrawer";
import AprHead from "../component/AprHead";
import dayjs from 'dayjs';
import ExportJsonExcel from "js-export-excel";
import {browserNavigator} from "../utils/DingUtils";
import {getSheetSaleOrder, getSheetSalOutStock, getSheetPrdBom, getSheetZdpPrdMo} from "./manuUtils";
import {getDate} from "../utils/CommonUtil";

const sysConst = require('../utils/SysConst');
const commonUtil = require('../utils/CommonUtil');
const dingUtil = require('../utils/DingUtils');
const appAction = require('../utils/AppAction');
const localUtil = require('../utils/LocalUtils');

const styles = {
    card: {paddingLeft:5,paddingRight:5,marginTop: 5},
    load: {zIndex: 999, position: 'absolute', top: '40%', left: '46%'}
};

/**
 * 制造指标统计
 * @param props
 * @returns {*}
 * @constructor
 */
const ManufactStat = (props) => {
    const [tableData, setTableData] = useState([]);
    const [proMoData, setProMoData] = useState([]);
    // 页面调用接口时，是否显示加载中动画
    const [loading, setLoading] = useState(false);
    // 选择日期抽屉 状态
    const [timeOpen, setTimeOpen] = useState(false);
    // 选择事业部抽屉 状态
    const [orgOpen, setOrgOpen] = useState(false);
    // 事业部内，区分用户角色（分属不同钉钉群组）
    const [orgRole, setOrgRole] = useState('');
    // 时间范围
    const [chooseTime, setChooseTime] = useState(sysConst.DATE_RANGE[2]);
    // 时间范围
    const [chooseMonth, setChooseMonth] = useState(dayjs().add(-1,'month').endOf('month'));
    const [dateRange, setDateRange] = useState({start: "", end: ""});
    // 事业部范围
    const [chooseOrg, setChooseOrg] = useState(commonUtil.getJsonItem(sysConst.MANU_ORG_RANGE, 17));
    // 是否可以操作全部组织数据
    const [totalOrg, setTotalOrg] = useState(false);
    // 是否显示下载图标
    const [showDownload, setShowDownload] = useState(false);
    // 主要数据对象
    const [mData, setMData] = useState({
        saleOrder: {},
        saleOutOrder: {},
        salOutStock: {},
        purOrder: {},
        prdMo: {},
        prdInStock: {},
        purReceive: {},
        zdpPrdMo: {},
        prdMoProc: {},
        zdpPrdMoLos: {},
        prdBom: {},
        prdBom0102: {},
        prdBom0103: {},
        prdBom0105: {},
        prdBom08: {},
        logiCost: {},
        outSrc: {},
        packBoxFee: {},
        pickCost: {},
        workHour: {list: []},
        manuOutQua: {},
        manuOutSuc: {},
        manuOutTim: {},
        groupTask: {}
    });

    // 第一次渲染时调用
    useEffect(() => {
        // 使用手机钉钉打开时, 设置标题内容
        dingUtil.setDingTitle('制造指标统计');
        if (props.location.search != '') {
            // 通过link的search对象 取得参数
            let params = qs.parse(props.location.search.slice(1));
            // 取得组织编码
            let orgNum = commonUtil.decrypt(params.o);
            // 如果组织标记为：total（全部组织）时，默认显示08组织数据
            if (commonUtil.decrypt(params.o) == 'total') {
                orgNum = '08';
                setTotalOrg(true);
            }
            // 取得组织内角色（群组区分）
            let role = commonUtil.decrypt(params.r);
            setOrgRole(role);
            // 08组织 并且 管理者权限 并且 如果是PC端 或者浏览器时 打开钉钉的话，显示下载图标
            if (orgNum === '08' && role === 'admin' && (browserNavigator.versions.Windows)) {
                setShowDownload(true);
            }
            // 设定事业部 显示 组织名称
            setChooseOrg(commonUtil.getJsonItemByNum(sysConst.MANU_ORG_RANGE, orgNum));
            // 取得钉钉登录信息
            appAction.loginDingTalk(
                () => {
                    getManuData(orgNum, chooseTime.label);
                }
            );
        }
    }, []);

    // 调用后端WebApi，取得金蝶 采购流程统计 相关单据统计数据
    const getManuDataStat = (orgNum, rangeName, dateRangeParam) => {
        // 加载中动画
        setLoading(true);
        // 根据条件 调用后端接口
        axios.post(sysConst.DOMAIN + "/kd/prd/statistics", {
            // 默认：查询大连组织下单据审批统计详情
            orgNum: orgNum, startDay: dateRangeParam.start, endDay: dateRangeParam.end
        })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    // 班组任务总数
                    let totalTask = 0;
                    // 班组任务及时总数
                    let totalTaskSuc = 0;
                    // 页面【班组任务完成及时率】用 数据
                    let groupTaskArr = [];
                    // 组织08时，且存在班组任务单
                    if (orgNum === '08' && res.data.group_task.length > 0) {
                        // 遍历后台工时数据，组装成 前台页面用的数据
                        res.data.group_task.forEach((value, index, array) => {
                            // 总工时
                            totalTask = totalTask + parseFloat(value.WORK_TOTAL);
                            totalTaskSuc = commonUtil.add(totalTaskSuc, value.WORK_SUCCESS);
                            // 每个班组
                            let workRate = commonUtil.percentage(parseFloat(value.WORK_SUCCESS), value.WORK_TOTAL);
                            // round(x)： 四舍五入。    floor(x)： 对 x 进行下舍入。
                            groupTaskArr.push({
                                team_name: value.TEAM_NAME.replace('班组', ''),
                                numerator : parseInt(value.WORK_SUCCESS),
                                denominator: parseInt(value.WORK_TOTAL),
                                rate: workRate
                            })
                        });
                    }
                    groupTaskArr = groupTaskArr.sort( (a, b) => {return b.rate - a.rate;});

                    // 页面【有效工时占比】用 数据
                    let workHours = [];
                    // 页面【订单额】用 数据
                    let saleOrderGroup = [];
                    // 08数字时，显示订单额明细
                    if (orgNum === '08' && res.data.sale_order_data.length > 0) {
                        // 兆和智能数据索引（事业部=-1，且客户是 兆和智能）

                        // 打补丁做法，因为 ERP中 出现2个客户，不正常情况（下面代码特殊处理）
                        let index05 = res.data.sale_order_data.findIndex(item => item.CUS_NUM == '13215500' && item.ORG_NUM == '-1');
                        let index052 = res.data.sale_order_data.findIndex(item => item.CUS_NUM == '13805710' && item.ORG_NUM == '-1');
                        let ordCnt = 0;
                        let ordAmt = 0;
                        if (index05 !== -1) {
                            ordCnt = parseFloat(res.data.sale_order_data[index05].CNT);
                            ordAmt = parseFloat(res.data.sale_order_data[index05].AMT);
                        }
                        if (index052 !== -1) {
                            ordCnt = ordCnt + parseFloat(res.data.sale_order_data[index052].CNT);
                            ordAmt = ordAmt + parseFloat(res.data.sale_order_data[index052].AMT);
                        }
                        if (ordCnt > 0) {
                            // 将兆和智能放入结果集
                            saleOrderGroup.push({
                                org_num: '05',
                                org_name: '兆和智能',
                                order_cnt: ordCnt,
                                order_amt: commonUtil.formatNumber(parseFloat(ordAmt)/10000,2) + '万'
                            })
                        }

                        // 临时存放数据
                        let orgNum = '';
                        let orgName = '';
                        let cnt = 0;
                        let amt = 0;
                        // 遍历后台工时数据，组装成 前台页面用的数据
                        res.data.sale_order_data.forEach((value, index, array) => {
                            // 非 兆和智能 客户的情况
                            if (!(value.CUS_NUM == '13215500' && value.ORG_NUM == '-1') && !(value.CUS_NUM == '13805710' && value.ORG_NUM == '-1')) {
                                if (orgNum != '' && orgNum != value.ORG_NUM) {
                                    saleOrderGroup.push({
                                        org_num: orgNum,
                                        org_name: orgName.replace('未知', '外部客户'),
                                        order_cnt: cnt,
                                        order_amt: commonUtil.formatNumber(parseFloat(amt)/10000,2) + '万'
                                    });
                                    cnt = 0;
                                    amt = 0;
                                }
                                orgNum = value.ORG_NUM;
                                orgName = value.ORG_NAME;
                                cnt = cnt + parseFloat(value.CNT);
                                amt = amt + parseFloat(value.AMT);
                            }
                        });
                        saleOrderGroup.push({
                            org_num: orgNum,
                            org_name: orgName.replace('未知', '外部客户'),
                            order_cnt: cnt,
                            order_amt: commonUtil.formatNumber(parseFloat(amt)/10000,2) + '万'
                        });
                        // 按照 金额降序排序
                        saleOrderGroup = saleOrderGroup.sort( (a, b) => {return b.order_amt.replace('万', '').replace(',', '') - a.order_amt.replace('万', '').replace(',', '');});
                    }

                    // 总ERP累计工时
                    let totalErp = 0;
                    // 总钉钉考勤工时
                    let totalDing = 0;
                    if (orgNum === '08' && rangeName !== '本年' && rangeName !== '本季' && rangeName !== '上季') {
                        // 遍历后台工时数据，组装成 前台页面用的数据
                        res.data.work_hours.forEach((value, index, array) => {
                            // 总工时
                            totalErp = totalErp + parseFloat(value.work_time);
                            totalDing = commonUtil.add(totalDing, value.ding_hour);
                            // 每个班组 工时占比
                            let workRate = commonUtil.percentage(parseFloat(value.work_time), value.ding_hour);
                            // round(x)： 四舍五入。    floor(x)： 对 x 进行下舍入。
                            workHours.push({
                                team_name: value.team_name.replace('班组', ''),
                                work_time: parseFloat(value.work_time),
                                ding_hour: Math.floor(value.ding_hour*10)/10,
                                work_rate: workRate
                            })
                        });
                        // 指标【有效工时占比】：按照 百分比 降序排序
                        workHours = workHours.sort( (a, b) => {return b.work_rate - a.work_rate;});
                    }

                    // BOM准确率 详细（按照BOM来源组织分类统计）
                    let bomDetail = [
                        {org: '0102', src: 0, chg: 0, opt: 0},
                        {org: '0103', src: 0, chg: 0, opt: 0},
                        {org: '0105', src: 0, chg: 0, opt: 0},
                        {org: '08', src: 0, chg: 0, opt: 0},
                    ];

                    // 组织：08兆和数字时，组装 BOM详细信息
                    if (orgNum === '08') {
                        // 遍历后台工时数据，组装成 前台页面用的数据
                        res.data.prd_bom_d_src_data.forEach((value, index, array) => {
                            switch (value.ORG_NUM) {
                                case '0102':
                                    bomDetail[0].src = parseInt(value.BILL_COUNT);
                                    break;
                                case '0103':
                                    bomDetail[1].src = parseInt(value.BILL_COUNT);
                                    break;
                                case '0105':
                                    bomDetail[2].src = parseInt(value.BILL_COUNT);
                                    break;
                                case '08':
                                    bomDetail[3].src = parseInt(value.BILL_COUNT);
                                    break;
                            }
                        });
                        res.data.prd_bom_d_chg_data.forEach((value, index, array) => {
                            switch (value.ORG_NUM) {
                                case '0102':
                                    bomDetail[0].chg = parseInt(value.BILL_COUNT);
                                    break;
                                case '0103':
                                    bomDetail[1].chg = parseInt(value.BILL_COUNT);
                                    break;
                                case '0105':
                                    bomDetail[2].chg = parseInt(value.BILL_COUNT);
                                    break;
                                case '08':
                                    bomDetail[3].chg = parseInt(value.BILL_COUNT);
                                    break;
                            }
                        });
                        res.data.prd_bom_d_opt_data.forEach((value, index, array) => {
                            switch (value.ORG_NUM) {
                                case '0102':
                                    bomDetail[0].opt = parseInt(value.BILL_COUNT);
                                    break;
                                case '0103':
                                    bomDetail[1].opt = parseInt(value.BILL_COUNT);
                                    break;
                                case '0105':
                                    bomDetail[2].opt = parseInt(value.BILL_COUNT);
                                    break;
                                case '08':
                                    bomDetail[3].opt = parseInt(value.BILL_COUNT);
                                    break;
                            }
                        });
                    }

                    // 给页面数据对象 赋值
                    setMData({
                        // 1.订单额 和 主营收入：
                        saleOrder: {
                            main:commonUtil.formatNumber(parseFloat(res.data.sale_order_amt)/10000,2) + '万' + ' (' + res.data.sale_order_cnt + '单)',
                            ruleT: '订单额：已审核销售订单价税合计总数',
                            listT: 'saleOrder',
                            list: saleOrderGroup
                        },
                        saleOutOrder: {
                            main:commonUtil.formatNumber(parseFloat(res.data.sale_out_amt)/10000,2) + '万' + ' (' + res.data.sale_out_cnt + '单)',
                            left: '总成本：' + commonUtil.formatNumber(parseFloat(res.data.sale_out_cost)/10000,2) + '万',
                            ruleT: '主营收入：已审核销售出库单价税合计总数',
                            ruleC: '总成本：销售出库单总成本，若未结账取不到成本时，则取得历史成本价格',
                        },
                        // 2.产品发货及时率​
                        salOutStock: {
                            main: commonUtil.percentage(res.data.sal_out_stock_suc, res.data.sal_out_stock_cnt) + '%',
                            left: '产品应发货数：' + res.data.sal_out_stock_cnt,
                            right:'及时发货数量：' + res.data.sal_out_stock_suc,

                            ruleT: '产品发货及时率：② / ① * 100%',
                            ruleC: '产品应发货数①：要货日期(发货通知单) 为指定时间范围的发货通知单对应的直接调拨单明细个数',
                            ruleB: '及时发货数量②：①的要货日期 > 对应的直接调拨单-审核日期的明细个数'
                        },
                        // 3.采购交付及时率 / 供应链交付及时率
                        purOrder: {
                            main: commonUtil.percentage(res.data.pur_order_suc, res.data.pur_order_cnt) + '%',
                            left: '采购应入库数：' + res.data.pur_order_cnt,
                            right: orgNum == '0603' ? '及时入库数量：' + res.data.pur_spec_suc : '及时入库数量：' + res.data.pur_order_suc,

                            ruleT: orgNum == '0603' ? '供应链交付及时率：② / ① * 100%' : '采购交付及时率：② / ① * 100%',
                            ruleC: '采购应入库数①：到货日期(采购申请单) 为指定时间范围的采购申请单对应的采购入库明细个数',
                            ruleB: orgNum == '0603' ? '及时入库数量②：采购入库单-审核日期 在 ①的到货日期 的 前五天 和 ①的到货日期之间的明细个数'
                                : '及时入库数量②：①的采购入库单-审核日的到货日期 > 对应期的明细个数'
                        },
                        // 4.生产订单完成及时率
                        prdMo: {
                            main: commonUtil.percentage(res.data.prd_mo_suc, res.data.prd_mo_cnt) + '%',
                            left:  '生产订单数：' + res.data.prd_mo_cnt,
                            right: '及时结案数：' + res.data.prd_mo_suc,
                            ruleT: '生产订单完成及时率：​② / ① * 100%',
                            ruleC: '生产订单数①：指定时间范围的生产订单明细个数',
                            ruleB: '及时结案数②：①的计划完工时间 > 结案日期的单据明细个数'
                        },
                        // 5.生产计划达成率
                        prdInStock: {
                            main: commonUtil.percentage(res.data.prd_in_stock_suc, res.data.prd_in_stock_cnt) + '%',
                            left:  '生产入库单数：' + res.data.prd_in_stock_cnt,
                            right: '及时入库数量：' + res.data.prd_in_stock_suc,
                            ruleT: '生产计划达成率：② / ① * 100%',
                            ruleC: '生产入库单数①：指定时间范围的生产入库单明细个数',
                            ruleB: '及时入库数量②：①对应的生产订单计划完工时间 > ①的审核时间明细个数'
                        },
                        // 6.采购品验收合格率
                        purReceive: {
                            main: commonUtil.percentage(res.data.pur_receive_cnt - res.data.pur_receive_ove,res.data.pur_receive_cnt) + '%',
                            left:'收料通知单数：' + res.data.pur_receive_cnt,
                            right: '有质量异议的收料单数：' + res.data.pur_receive_ove,
                            ruleT: '采购品验收合格率=无质量异议的收料单数/收料通知单总数'
                        },
                        // 7.成品检验合格率
                        zdpPrdMo: {
                            main: commonUtil.percentage((res.data.zdp_prd_mo_cnt - res.data.zdp_prd_mo_ove) < 0 ? 0 : (res.data.zdp_prd_mo_cnt - res.data.zdp_prd_mo_ove),res.data.zdp_prd_mo_cnt) + '%',
                            left:  '生产订单数：' + res.data.zdp_prd_mo_cnt,
                            right: '质量异议单数：' + res.data.zdp_prd_mo_ove,
                            ruleT: '成品检验合格率：（① - ②） / ① * 100%',
                            ruleC: '生产订单数①：指定完工日期范围，所有生产订单明细个数',
                            ruleB: '质量异议单数②：指定时间范围的自制品质量异议单（成品检验）的单据数'
                        },
                        // 8.生产过程检验合格率
                        prdMoProc: {
                            main: commonUtil.percentage((res.data.zdp_prd_mo_cnt - res.data.zdp_prd_mo_pro) < 0 ? 0 : (res.data.zdp_prd_mo_cnt - res.data.zdp_prd_mo_pro),res.data.zdp_prd_mo_cnt) + '%',
                            left:  '生产订单数：' + res.data.zdp_prd_mo_cnt,
                            right: '质量异议单数：' + res.data.zdp_prd_mo_pro,
                            ruleT: '生产过程检验合格率：（① - ②） / ① * 100%',
                            ruleC: '生产订单数①：指定完工日期范围，所有生产订单明细个数',
                            ruleB: '质量异议单数②：指定时间范围的自制品质量异议单（过程检验）的单据数'
                        },
                        // 9.产品质量损失额
                        zdpPrdMoLos: {
                            main: '',
                            left:  '生产质量损失额：' + res.data.zdp_prd_tec_los,
                            right: '设计质量损失额：' + res.data.zdp_prd_manu_los,
                            ruleT: '生产质量损失额=自制品且责任部门属性是基本生产部门的损失金额合计',
                            ruleC: '设计质量损失额=自制品且责任部门属性是研发部门的损失金额合计'
                        },
                        // 10.BOM准确率
                        prdBom: {
                            main: commonUtil.percentage((res.data.prd_bom_cnt - res.data.prd_bom_ove) < 0 ? 0 : (res.data.prd_bom_cnt - res.data.prd_bom_ove),res.data.prd_bom_cnt) + '%',
                            left:'生产用料清单数：' + res.data.prd_bom_cnt,
                            right: '技术原因变更数：' + res.data.prd_bom_ove,
                            ruleT: 'BOM准确率：(① -​②) / ① * 100%',
                            ruleC: '生产用料清单数①：指定时间范围的生产用料清单总数',
                            ruleB: orgNum == '0603' ? '技术原因变更数②：①对应的生产补料单（变更原因为：技术原因）的单据总数'
                                : '技术原因变更数②：①对应的生产用料清单变更单（变更原因为：技术原因，BOM来源为08组织）的单据总数'
                        },
                        // 11.BOM准确率-详细 兆和数字
                        prdBom0102: {
                            main: commonUtil.percentage((bomDetail[0].src - bomDetail[0].chg) < 0 ? 0 : (bomDetail[0].src - bomDetail[0].chg), bomDetail[0].src) + '%',
                            left:'生产用料清单数：' + bomDetail[0].src,
                            right: '技术错误数：' + bomDetail[0].chg,
                            third: '技术优化数：' + bomDetail[0].opt,
                            ruleT: 'BOM准确率：(① -​②) / ① * 100%',
                            ruleC: '生产用料清单数①：指定时间范围的生产用料清单（BOM来源组织为环保机电）总数',
                            ruleB: '技术错误数②：①对应的生产用料清单变更单（变更原因为：技术原因）的单据总数',
                            ruleTh: '技术优化数③：①对应的生产用料清单变更单（变更原因为：技术优化）的单据总数'
                        },
                        prdBom0103: {
                            main: commonUtil.percentage((bomDetail[1].src - bomDetail[1].chg) < 0 ? 0 : (bomDetail[1].src - bomDetail[1].chg), bomDetail[1].src) + '%',
                            left:'生产用料清单数：' + bomDetail[1].src,
                            right: '技术错误数：' + bomDetail[1].chg,
                            third: '技术优化数：' + bomDetail[1].opt,
                            ruleT: 'BOM准确率：(① -​②) / ① * 100%',
                            ruleC: '生产用料清单数①：指定时间范围的生产用料清单（BOM来源组织为有机废气）总数',
                            ruleB: '技术错误数②：①对应的生产用料清单变更单（变更原因为：技术原因）的单据总数',
                            ruleTh: '技术优化数③：①对应的生产用料清单变更单（变更原因为：技术优化）的单据总数'
                        },
                        prdBom0105: {
                            main: commonUtil.percentage((bomDetail[2].src - bomDetail[2].chg) < 0 ? 0 : (bomDetail[2].src - bomDetail[2].chg), bomDetail[2].src) + '%',
                            left:'生产用料清单数：' + bomDetail[2].src,
                            right: '技术错误数：' + bomDetail[2].chg,
                            third: '技术优化数：' + bomDetail[2].opt,
                            ruleT: 'BOM准确率：(① -​②) / ① * 100%',
                            ruleC: '生产用料清单数①：指定时间范围的生产用料清单（BOM来源组织为大连除尘）总数',
                            ruleB: '技术错误数②：①对应的生产用料清单变更单（变更原因为：技术原因）的单据总数',
                            ruleTh: '技术优化数③：①对应的生产用料清单变更单（变更原因为：技术优化）的单据总数'
                        },
                        prdBom08: {
                            main: commonUtil.percentage((bomDetail[3].src - bomDetail[3].chg) < 0 ? 0 : (bomDetail[3].src - bomDetail[3].chg), bomDetail[3].src) + '%',
                            left:'生产用料清单数：' + bomDetail[3].src,
                            right: '技术错误数：' + bomDetail[3].chg,
                            third: '技术优化数：' + bomDetail[3].opt,
                            ruleT: 'BOM准确率：(① -​②) / ① * 100%',
                            ruleC: '生产用料清单数①：指定时间范围的生产用料清单（BOM来源组织为兆和数字）总数',
                            ruleB: '技术错误数②：①对应的生产用料清单变更单（变更原因为：技术原因）的单据总数',
                            ruleTh: '技术优化数③：①对应的生产用料清单变更单（变更原因为：技术优化）的单据总数'
                        },
                        // 12.物流费用
                        logiCost: {
                            main: commonUtil.formatNumber(parseFloat(res.data.logi_cost_amt)/10000,2) + ' 万',
                            left: '物流费用清单数：' + res.data.logi_cost_cnt,
                            ruleT: '物流费用：指定时间范围的物流费用清单的发货金额合计'
                        },
                        // 13.外协费用
                        outSrc: {
                            main: commonUtil.formatNumber(parseFloat(res.data.out_src_amt)/10000,2) + ' 万',
                            left: '外协明细数：' + res.data.out_src_cnt,
                            ruleT: '外协费用：统计指定时间范围采购订单明细中物料属性：制造外协=true 的价税合计'
                        },
                        // 14.包装箱费
                        packBoxFee: {
                            main: commonUtil.formatNumber(parseFloat(res.data.pack_box_fee_amt)/10000,2) + ' 万',
                            left:  '包装箱明细数：' + res.data.pack_box_fee_cnt,
                            ruleT: '包装箱费：统计指定时间范围采购订单明细中物料属性是包装物的价税合计'
                        },
                        // 15.领料成本金额
                        pickCost: {
                            main: commonUtil.formatNumber(parseFloat(res.data.pick_bill_money)/10000 + parseFloat(res.data.deli_bill_money)/10000,2) + ' 万',
                            left:  '生产领料成本：' + commonUtil.formatNumber(parseFloat(res.data.pick_bill_money)/10000,2) + ' 万',
                            right:  '其他出库成本：' + commonUtil.formatNumber(parseFloat(res.data.deli_bill_money)/10000,2) + ' 万',
                            ruleT: '领料成本金额（除外协和包装箱）：生产领料成本 + 其他出库成本'
                        },
                        // 16.有效工时占比
                        workHour: {
                            main: commonUtil.percentage(totalErp, totalDing) + '%',
                            ruleT: '有效工时占比：(生产汇报班组工时 / 钉钉考勤班组工时) * 100%',
                            listT: 'workHours',
                            list: workHours
                        },
                        // 17.制造外协质量损失额
                        manuOutQua: {
                            main: commonUtil.formatNumber(parseFloat(res.data.manu_out_lost_ord_los)/10000,2) + ' 万',
                            left:  '质量异议单数：' + res.data.manu_out_lost_qua_cnt,
                            ruleT: '制造外协质量损失额：统计指定时间范围采购订单(明细物料属性为制造外协)下推的质量异议单中记录的质量损失金额合计'
                        },
                        // 18.制造外协合格率
                        manuOutSuc: {
                            main: commonUtil.percentage((res.data.manu_out_lost_ord_cnt - res.data.manu_out_lost_qua_cnt) < 0 ? 0 : (res.data.manu_out_lost_ord_cnt - res.data.manu_out_lost_qua_cnt),res.data.manu_out_lost_ord_cnt) + '%',
                            left:  '制造外协采购订单数：' + res.data.manu_out_lost_ord_cnt,
                            right: '制造外协质量损失额：' + commonUtil.formatNumber(parseFloat(res.data.manu_out_lost_ord_los)/10000,2) + ' 万',
                            ruleT: '制造外协合格率：（① - ​②） / ① * 100%',
                            ruleC: '制造外协采购订单数①：统计 已审核 指定时间范围，指定组织的 采购订单(明细物料属性为制造外协)个数',
                            ruleB: '质量异议单数②：满足上述条件的采购订单，存在的质量异议单的单据数'
                        },
                        // 19.制造外协完成及时率
                        manuOutTim: {
                            main: commonUtil.percentage(res.data.manu_out_timely_suc,res.data.manu_out_timely_cnt) + '%',
                            left:  '采购应入库数：' + res.data.manu_out_timely_cnt,
                            right: '及时入库数量：' + res.data.manu_out_timely_suc,
                            ruleT: '制造外协完成及时率：② / ① * 100%',
                            ruleC: '采购应入库数①：到货日期(采购申请单) 为指定时间的采购申请单(外协)对应的采购入库明细个数',
                            ruleB: '及时入库数量②：①的到货日期 >= 对应的采购入库单-审核日期的明细个数'
                        },
                        // 20. 班组任务完成及时率
                        groupTask: {
                            main: commonUtil.percentage(totalTaskSuc, totalTask) + '%',
                            ruleT: '班组任务完成及时率：(及时完成数 / 班组任务总数) * 100%',
                            listT: 'groupTask',
                            list: groupTaskArr
                        }
                    });
                } else {
                    alert(`getManuDataStat interface no data.`)
                }
                setLoading(false);
            }).catch(error => {
                alert('getManuDataStat error!');
                console.log("getManuDataStat err, " + JSON.stringify(error));
                setLoading(false);
            }
        )
    };

    const changeTime = (rangeItem) => {
        // 关闭 抽屉
        setTimeOpen(false);
        // 更新 选中值
        setChooseTime(rangeItem);
        // 取得 钉钉登录信息
        appAction.loginDingTalk(
            () => {
                // 调用金蝶接口 取得数据
                getManuData(chooseOrg.num, rangeItem.label);
                switch (currentTab) {
                    case '5':
                        // 如果当前TAB页 是 【产品工时】则，调用 生产工时接口
                        getMOStat(chooseOrg.num, rangeItem.label, true);
                        break;
                    case '6':
                        getProMoStat(chooseOrg.num, rangeItem.label, true);
                        break;
                    default:
                        break;
                }
            }
        );
    };

    const changeOrg = (orgItem) => {
        // 关闭 抽屉
        setOrgOpen(false);
        // 更新 选中值
        setChooseOrg(orgItem);
        // 取得 钉钉登录信息
        appAction.loginDingTalk(
            () => {
                // 调用金蝶接口 取得数据
                getManuData(orgItem.num, chooseTime.label);
            }
        );
    };

    // 取得制造指标数据
    const getManuData = async (orgNum, rangeName) => {
        // 如果没有 组织，则不查询接口，直接返回
        if (orgNum === '') {
            return;
        }
        // 日期范围 DEMO：2023-01-01 ~ 2023-01-31
        let dateRangeParam = {};
        // 根据页面选定日期范围（本周，本月等）：取得开始日期，结束日期
        if (rangeName === '月份') {
            // 默认选择月份时，为上个月数据
            setChooseMonth(dayjs().add(-1,'month').endOf('month'));
            dateRangeParam = commonUtil.getDateRange('上月');
        } else {
            dateRangeParam = commonUtil.getDateRange(rangeName);
            setDateRange(dateRangeParam);
        }
        // 调用金蝶接口 取得数据
        getManuDataStat(orgNum, rangeName, dateRangeParam);
    };

    // 产品工时
    const getMOStat = async (orgNum, rangeName, lastMon) => {
        // 如果没有 组织，则不查询接口，直接返回
        if (orgNum === '') {
            return;
        }
        // 日期范围 DEMO：2023-01-01 ~ 2023-01-31
        let dateRangeParam = {};
        // 根据页面选定日期范围（本周，本月等）：取得开始日期，结束日期
        if (rangeName === '月份') {
            // lastMon = true 时，为上个月数据
            if (lastMon) {
                setChooseMonth(dayjs().add(-1,'month').endOf('month'));
                dateRangeParam = commonUtil.getDateRange('上月');
            } else {
                let endDay = chooseMonth.format('YYYY-MM-DD');
                dateRangeParam = {start: endDay.substr(0,8) + '01', end: endDay};
            }
        } else {
            dateRangeParam = commonUtil.getDateRange(rangeName);
            setDateRange(dateRangeParam);
        }
        // 调用金蝶接口 取得数据
        await getMOHourStat(orgNum, rangeName, dateRangeParam);
    };

    // 取得生产订单领料信息
    const getProMoStat = async (orgNum, rangeName, lastMon) => {
        // 如果没有 组织，则不查询接口，直接返回
        if (orgNum === '') {
            return;
        }
        // 日期范围 DEMO：2023-01-01 ~ 2023-01-31
        let dateRangeParam = {};
        // 根据页面选定日期范围（本周，本月等）：取得开始日期，结束日期
        if (rangeName === '月份') {
            // lastMon = true 时，为上个月数据
            if (lastMon) {
                setChooseMonth(dayjs().add(-1,'month').endOf('month'));
                dateRangeParam = commonUtil.getDateRange('上月');
            } else {
                let endDay = chooseMonth.format('YYYY-MM-DD');
                dateRangeParam = {start: endDay.substr(0,8) + '01', end: endDay};
            }
        } else {
            dateRangeParam = commonUtil.getDateRange(rangeName);
            setDateRange(dateRangeParam);
        }
        // 调用金蝶接口 取得数据
        await getProMoStatApi(orgNum, rangeName, dateRangeParam);
    };

    /**
     * 头部选择 月份状态后，切换月份触发此方法
     * @param date
     * @param dateString
     */
    const changeMon = (date, dateString) => {
        if (date != null) {
            setChooseMonth(date);
            let dateRange = {start: date.startOf('month').format('YYYY-MM-DD'), end: date.endOf('month').format('YYYY-MM-DD')};
            // 根据 TAB 判断查询内容
            switch (currentTab) {
                case '5':
                    getMOHourStat(chooseOrg.num, chooseTime.label, dateRange);
                    break;
                case '6':
                    getProMoStatApi(chooseOrg.num, chooseTime.label,dateRange);
                    break;
                default:
                    getManuDataStat(chooseOrg.num, chooseTime.label, dateRange);
                    break;
            }
        }
    };

    // 刷新
    const refresh = () => {
        getManuData(chooseOrg.num, chooseTime.label)
    };

    // 调用后端WebApi，产品工时 接口
    const getMOHourStat = async (orgNum, rangeName, dateRangeParam) => {
        // 加载中动画
        setLoading(true);
        // 根据条件 调用后端接口
        await axios.post(sysConst.DOMAIN + "/kd/mo/hour/statistics", {
            orgNum: orgNum, startDay: dateRangeParam.start, endDay: dateRangeParam.end
        })
            .then(res => {
                // 新的结果集（table数据集）
                let tmpData = [];
                if (res.data.success) {
                    // 头部 标准工时总计
                    let stdHours = 0;
                    // 头部 报告工时总计
                    let repHours = 0;
                    // 循环原始数据集
                    res.data.data.forEach((currentValue, index) => {
                        // 累计头部总计数据
                        stdHours = commonUtil.add(stdHours, parseFloat(currentValue.std));
                        repHours = commonUtil.add(repHours, parseFloat(currentValue.moh));
                        // 更改原始数据集（修改状态显示中文，添加工时差，工时比）
                        tmpData.push({
                            ...currentValue,
                            "status": currentValue.stat === '6' ? "结案" : "结算",
                            "diff": commonUtil.sub(parseFloat(currentValue.moh), parseFloat(currentValue.std)),
                            "perc": commonUtil.percentage(parseFloat(currentValue.moh), parseFloat(currentValue.std))
                        });
                    });
                    // 头部信息
                    setTableStat({"rowcount":res.data.rowcount,"stdHours":stdHours,"repHours":repHours,"diff":commonUtil.sub(repHours,stdHours)});
                    // table 列表数据
                    localUtil.setSessionItem(sysConst.DOWNLOAD_DATA, tmpData);
                    setTableData(tmpData);
                } else {
                    if (!res.data.success) {
                        alert("调用金蝶API异常，请尝试刷新页面！");
                    } else {
                        alert(`getApprovalStat no data`)
                    }
                }
                setLoading(false);
            }).catch(error => {
            alert('getApprovalStat error');
            console.log("getApprovalStat err, " + JSON.stringify(error));
            setLoading(false);
        })
    };

    const changeTab = (key) => {
        // 当前TAB切换
        setCurrentTab(key);
        switch (key) {
            case '5':
                getMOStat(chooseOrg.num, chooseTime.label, false);
                break;
            case '6':
                getProMoStat(chooseOrg.num, chooseTime.label, false);
                break;
            default:
                break;
        }
    };

    const popoverContent = (
        <div style={{fontSize:11}}>
            <Col span={24}>工时差额总计 = 汇报工时总计 - 标准工时总计</Col>
        </div>
    );


    // 调用后端WebApi，产品工时 接口
    const getChildTable = async (record) => {
        // 加载中动画
        setLoading(true);
        // 根据条件 调用后端接口
        await axios.post(sysConst.DOMAIN + "/kd/prdppbom/query", {
            billNo: record.billNo, matNum: record.matNum
        })
            .then(res => {
                if (res.data.success) {
                    setLoading(false);
                    proMoData[record.idx] = {...proMoData[record.idx],"bom": res.data.data};
                    setProMoData(proMoData);
                } else {
                    if (!res.data.success) {
                        alert("调用金蝶API异常，请尝试刷新页面！");
                    } else {
                        alert(`getChildTable no data`)
                    }
                }
                setLoading(false);
            }).catch(error => {
            alert('getChildTable error');
            console.log("getChildTable err, " + JSON.stringify(error));
            setLoading(false);
        })
    };

    const expandedRowRender = (record, index, indent, expanded) => {
        if (!expanded) {
            return;
        }
        const columns = [
            {key: 'matNumber',dataIndex: 'matNumber',width: 90,title: '子项物料编码',align:'center'},
            {key: 'matName',dataIndex: 'matName',width: 180,title: '子项物料名称',align:'center'},
            {key: 'spec',dataIndex: 'spec',width: 160,title: '规格型号',align:'left'},
            {key: 'unit',dataIndex: 'unit',width: 80,title: '子项单位',align:'center'},
            {key: 'mustQty',dataIndex: 'mustQty',width: 80,title: '应发数量',align:'center',render: (text) => {return <>{commonUtil.formatNumber(parseFloat(text),6)}</>}},
            {key: 'pickedQty',dataIndex: 'pickedQty',width: 80,title: '已领数量',align:'center',render: (text) => {return <>{commonUtil.formatNumber(parseFloat(text),6)}</>}}
        ];
        return <Table columns={columns} dataSource={record.dtl} pagination={false} />;
    };

    /************** 【产品工时】页签，Table 表格相关内容 **************/
    const [currentTab, setCurrentTab] = useState('1');
    const [tableStat, setTableStat] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getWHColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    // placeholder={`Search ${dataIndex}`}
                    placeholder={`请输入查询内容`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button size="small" style={{width: 90}} type="primary" icon={<SearchOutlined />}
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    >
                        查询
                    </Button>
                    <Button size="small" style={{width: 90}}
                            onClick={() => clearFilters && handleReset(clearFilters)}
                    >
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const workHoursColumns = [
        {key: 'num',dataIndex: 'num',width: 118,title: '物料编码',fixed:true,...getWHColumnSearchProps('num')},
        {key: 'name',dataIndex: 'name',width: 180,title: '物料名称',...getWHColumnSearchProps('name')},
        {key: 'spec',dataIndex: 'spec',width: 180,title: '规格型号',...getWHColumnSearchProps('spec')},
        {key: 'qty',dataIndex: 'qty',width: 100,title: '数量',align:'right',sorter: (a, b) => a.qty - b.qty},
        {key: 'unit',dataIndex: 'unit',width: 70,title: '单位',align:'center'},
        {key: 'stat',dataIndex: 'status',width: 80,title: '业务状态',align:'center',...getWHColumnSearchProps('status')},
        {key: 'std',dataIndex: 'std',width: 80,title: '标准工时',align:'center',sorter: (a, b) => a.std.length - b.std.length},
        {key: 'moh',dataIndex: 'moh',width: 88,title: `汇报工时`,align:'center',sorter: (a, b) => a.moh.length - b.moh.length},
        {key: 'diff',dataIndex: 'diff',width: 90,title: `工时差`,align:'center',sorter: (a, b) => a.diff - b.diff},
        {key: 'perc',dataIndex: 'perc',width: 90,title: `工时比`,align: 'center',sorter: (a, b) => a.perc - b.perc,render: (perc) => {return <>{perc} %</>}},
        //     sortDirections: ['descend', 'ascend'],
    ];

    /************** 【领料详情】页签，Table 表格相关内容 **************/

    const [proMoSearchText, setProMoSearchText] = useState('');
    const [proMoSearchedColumn, setProMoSearchedColumn] = useState('');
    const proMoSearchInput = useRef(null);
    const handleProMoSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setProMoSearchText(selectedKeys[0]);
        setProMoSearchedColumn(dataIndex);
    };
    const handleProMoReset = (clearFilters) => {
        clearFilters();
        setProMoSearchText('');
    };
    const getPorMoColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={proMoSearchInput}
                    // placeholder={`Search ${dataIndex}`}
                    placeholder={`请输入查询内容`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleProMoSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button size="small" style={{width: 90}} type="primary" icon={<SearchOutlined />}
                            onClick={() => handleProMoSearch(selectedKeys, confirm, dataIndex)}
                    >
                        查询
                    </Button>
                    <Button size="small" style={{width: 90}}
                            onClick={() => clearFilters && handleProMoReset(clearFilters)}
                    >
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => proMoSearchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            proMoSearchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[proMoSearchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const pickMatlColumns = [
        {key: 'billNo',dataIndex: 'billNo',width: 90,title: '生产订单',...getPorMoColumnSearchProps('billNo')},
        {key: 'matNum',dataIndex: 'matNum',width: 100,title: '物料编码',...getPorMoColumnSearchProps('matNum')},
        {key: 'matName',dataIndex: 'matName',width: 140,title: '物料名称',...getPorMoColumnSearchProps('matName')},
        {key: 'spec',dataIndex: 'spec',width: 160,title: '规格型号',...getPorMoColumnSearchProps('spec')},
        {key: 'qty',dataIndex: 'qty',width: 60,title: '数量',align:'right'
            ,sorter: (a, b) => a.qty - b.qty
            ,render:(text, row, index)=>{return <span>{commonUtil.formatNumber(parseFloat(text),2)}</span>}},
        {key: 'unit',dataIndex: 'unit',width: 40,title: '单位',align:'center'},
        {key: 'status',dataIndex: 'status',width: 60,title: '业务状态',align:'center',...getPorMoColumnSearchProps('status')},
        {key: 'pickStatus',dataIndex: 'pickStatus',width: 70,title: '领料状态',align:'center',...getPorMoColumnSearchProps('pickStatus')},
        {key: 'totalMustQty',dataIndex: 'totalMustQty',width: 70,title: '应领数量',align:'right'
            ,render:(text, row, index)=>{return <span>{commonUtil.formatNumber(parseFloat(text),2)}</span>}},
        {key: 'totalPickQty',dataIndex: 'totalPickQty',width: 68,title: '已领数量',align:'right'
            ,render:(text, row, index)=>{return <span>{commonUtil.formatNumber(parseFloat(text),2)}</span>}},
        {key: 'percent',dataIndex: 'percent',width: 60,title: '领取比例',align:'right'
            , sorter: (a, b) => a.percent - b.percent
            , render: (text, row, index) => {
                return <span>{text}%</span>
            }
        },
    ];

    // 调用后端WebApi，产品工时 接口
    const getProMoStatApi = async (orgNum, rangeName, dateRangeParam) => {
        // 加载中动画
        setLoading(true);
        // 根据条件 调用后端接口
        await axios.post(sysConst.DOMAIN + "/kd/prd/ppbom/query", {
            orgNum: orgNum, startDay: dateRangeParam.start, endDay: dateRangeParam.end
        })
            .then(res => {
                if (res.data.success) {
                    let newList = [];
                    // 遍历 结果集
                    res.data.data.forEach((value, index, array) => {
                        newList.push({...value, "percent" : commonUtil.percentage(value.totalPickQty, value.totalMustQty)})
                    });
                    setProMoData(newList);
                    localUtil.setSessionItem(sysConst.DOWNLOAD_DATA, newList);
                } else {
                    if (!res.data.success) {
                        alert("调用金蝶API异常，请尝试刷新页面！");
                    } else {
                        alert(`getApprovalStat no data`)
                    }
                }
                setLoading(false);
            }).catch(error => {
            alert('getApprovalStat error');
            console.log("getApprovalStat err, " + JSON.stringify(error));
            setLoading(false);
        })
    };

    /**
     *  导出数据
     */
    const download = async () => {
        // let { data } = await axios.get(`http://localhost:3000/leave`);
        // console.log(data1);
        // let sheet = XLSX.utils.json_to_sheet(data1);
        // let sheet2 = XLSX.utils.json_to_sheet(data2);
        // let book = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(book, sheet, 'sheet1');
        // XLSX.utils.book_append_sheet(book, sheet2, 'sheet2');
        // XLSX.writeFile(book, `userlist${new Date().getTime()}.xls`);

        let option = {};
        option.fileName = '制造指标统计';
        option.datas = [];
        // 组装sheet
        // 1.营收及成本
        option.datas.push(getSheetSaleOrder(mData));
        // 2.生产运营效率
        option.datas.push(getSheetSalOutStock(mData));
        // 3.技术BOM
        option.datas.push(getSheetPrdBom(mData));
        // 4.质量&损耗
        option.datas.push(getSheetZdpPrdMo(mData));
        // 5.产品工时
        localUtil.removeSessionStore(sysConst.DOWNLOAD_DATA);
        await getMOStat(chooseOrg.num, chooseTime.label, false);
        let oldData = localUtil.getSessionItem(sysConst.DOWNLOAD_DATA);
        let newData = [];
        // 循环原始数据集
        oldData.forEach((currentValue, index) => {
            newData.push({
                ...currentValue,
                "perc": currentValue.perc + '%'
            });
        });
        option.datas.push({
            sheetName: '产品工时',
            sheetData: newData,
            sheetFilter: ["num", "name", "spec", "qty", "unit", "status", "std", "moh", "diff", "perc"],
            sheetHeader: ['物料编码', '物料名称', '规格型号', '数量', '单位', '业务状态', '标准工时', '汇报工时', '工时差', '工时比'],
            columnWidths: [6, 14, 14, 4, 2, 4, 4, 4, 4, 4]
        });

        // 6.领料详情
        localUtil.removeSessionStore(sysConst.DOWNLOAD_DATA);
        await getProMoStat(chooseOrg.num, chooseTime.label, false);
        newData = [];
        // 循环原始数据集
        localUtil.getSessionItem(sysConst.DOWNLOAD_DATA).forEach((currentValue, index) => {
            newData.push({
                ...currentValue,
                "qty":  commonUtil.formatNumber(parseFloat(currentValue.qty), 2),
                // "totalMustQty":  commonUtil.formatNumber(currentValue.totalMustQty, 2),
                "percent": currentValue.percent + '%'
            });
        });
        option.datas.push({
            sheetName: '领料详情',
            sheetData: newData,
            sheetFilter: ["billNo", "matNum", "matName", "spec", "qty", "unit", "status", "pickStatus", "totalMustQty", "totalPickQty","percent"],
            sheetHeader: ['生产订单', '物料编码', '物料名称', '规格型号', '数量', '单位', '业务状态', '领料状态', '应领数量', '已领数量','领取比例'],
            columnWidths: [5, 6, 14, 14, 5, 4, 4, 5, 5, 5]
        });
        let toExcel = new ExportJsonExcel(option); //new
        toExcel.saveExcel(); //保存
    };

    return (
        <>
            {/* 加载中动画，居中显示 */}
            <div style={styles.load}><Spin size="large" spinning={loading}/></div>
            {/* 审批统计 固定头部内容，【时间范围：本周，本月，本年等】【事业部：大连兆和总部等等...】 */}
            {chooseOrg.num !== '01' &&
            <AprHead setTimeOpen={setTimeOpen} chooseTime={chooseTime} setOrgOpen={setOrgOpen} chooseOrg={chooseOrg}
                     dateRange={dateRange} changeMon={changeMon} selectedMon={chooseMonth} showIcon={totalOrg}
                     showDownload={showDownload} download={download} refresh={refresh}/>}
            {/* 选择日期 抽屉 */}
            <DateRangeDrawer timeOpen={timeOpen} setOpen={setTimeOpen} changeTime={changeTime} range={'all'}/>
            {/* 选择事业部 抽屉 */}
            {totalOrg && <ManuOrgListDrawer orgOpen={orgOpen} setOpen={setOrgOpen} changeOrg={changeOrg}/>}

            {chooseOrg.num == '08' &&
            <Tabs
                defaultActiveKey="1"
                onChange={changeTab}
                items={[
                    {
                        key: '1', label: `营收及成本`, children: (
                            <Row style={{marginTop: -10}}>
                                <Col span={12} style={styles.card}><ManuListCard bk={'#EFF3FA'} msg={"订单额"} dat={mData.saleOrder}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#EFF3FA'} msg={"主营收入"} dat={mData.saleOutOrder}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"领料成本金额"} dat={mData.pickCost}/></Col>
                                {orgRole === 'admin' &&
                                <>
                                    <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"物流费用"} dat={mData.logiCost}/></Col>
                                    <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"外协费用"} dat={mData.outSrc}/></Col>
                                    <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"包装箱费"} dat={mData.packBoxFee}/></Col>
                                </>}
                            </Row>
                        )
                    }
                    , {
                        key: '2', label: `生产运营效率`, children: (
                            <Row style={{marginTop: -10}}>
                                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"产品发货及时率"} dat={mData.salOutStock}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"采购交付及时率"} dat={mData.purOrder}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"生产订单完成及时率"} dat={mData.prdMo}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"生产计划达成率"} dat={mData.prdInStock}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"制造外协完成及时率"} dat={mData.manuOutTim}/></Col>
                                <Col span={12} style={styles.card}>&nbsp;</Col>

                                {/* 管理者 */}
                                {orgRole === 'admin' &&
                                <>
                                    <Col span={12} style={styles.card}><ManuListCard bk={'#FAFAFA'} msg={"班组任务完成及时率"} dat={mData.groupTask}/></Col>
                                    {(chooseTime.label !== '本年' && chooseTime.label !== '本季' && chooseTime.label !== '上季') &&
                                    <Col span={12} style={styles.card}><ManuListCard bk={'#FAFAFA'} msg={"有效工时占比"} dat={mData.workHour}/></Col>}
                                </>}
                                {/* 普通用户 */}
                                {orgRole !== 'admin' &&
                                <>
                                    {(chooseTime.label !== '本年' && chooseTime.label !== '本季' && chooseTime.label !== '上季') &&
                                    <Col span={12} style={styles.card}><ManuListCard bk={'#FAFAFA'} msg={"有效工时占比"} dat={mData.workHour}/></Col>}
                                </>}
                            </Row>
                        )
                    }
                    , {
                        key: '3', label: `技术BOM`, children: (
                            <Row style={{marginTop: -10}}>
                                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"BOM准确率_环保机电"} dat={mData.prdBom0102}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"BOM准确率_有机废气"} dat={mData.prdBom0103}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"BOM准确率_大连除尘"} dat={mData.prdBom0105}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"BOM准确率_兆和数字"} dat={mData.prdBom08}/></Col>
                            </Row>
                        )
                    }
                    , {
                        key: '4', label: `质量&损耗`, children: (
                            <Row style={{marginTop: -10}}>
                                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"成品检验合格率"} dat={mData.zdpPrdMo}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"生产过程检验合格率"} dat={mData.prdMoProc}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"采购品验收合格率"} dat={mData.purReceive}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"制造外协合格率"} dat={mData.manuOutSuc}/></Col>
                                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"产品质量损失额"} dat={mData.zdpPrdMoLos}/></Col>
                                {/*<Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"制造外协质量损失额"} dat={mData.manuOutQua}/></Col>*/}
                            </Row>
                        )
                    }
                    , {
                        key: '5', label: `产品工时`, children: (
                            <Row style={{marginTop: -14}}>
                                <Col span={12} style={styles.card}>查询数量行数：{tableStat.rowcount}</Col>
                                <Col span={12} style={styles.card}>
                                    <span style={{fontSize: 12}}>
                                        <Popover content={popoverContent}>
                                            工时差额总计 <QuestionCircleOutlined style={{fontSize: 11}}/>：{tableStat.diff}
                                        </Popover>
                                    </span>
                                </Col>
                                <Col span={12} style={styles.card}>标准工时总计：{tableStat.stdHours}</Col>
                                <Col span={12} style={styles.card}>汇报工时总计：{tableStat.repHours}</Col>
                                <Table bordered style={{marginTop: 6}} scroll={{x: 1100, y: 'calc(100vh - 339px)'}} pagination={false}
                                       columns={workHoursColumns}
                                       dataSource={tableData}
                                       summary={(pageData) => {
                                           let totalStd = 0;
                                           let totalMoh = 0;
                                           let totalDiff = 0;
                                           pageData.forEach(({std, moh, diff}) => {
                                               totalStd = commonUtil.add(totalStd, parseFloat(std));
                                               totalMoh = commonUtil.add(totalMoh, parseFloat(moh));
                                               totalDiff = commonUtil.add(totalDiff, parseFloat(diff));
                                           });
                                           return (
                                               <Table.Summary fixed>
                                                   <Table.Summary.Row>
                                                       <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                                       <Table.Summary.Cell index={1} colSpan={4}>&nbsp;</Table.Summary.Cell>
                                                       <Table.Summary.Cell index={2} colSpan={2} align="right">{totalStd}</Table.Summary.Cell>
                                                       <Table.Summary.Cell index={3} align="center">{totalMoh}</Table.Summary.Cell>
                                                       <Table.Summary.Cell index={4} align="center">{totalDiff}</Table.Summary.Cell>
                                                       <Table.Summary.Cell index={5}>&nbsp;</Table.Summary.Cell>
                                                   </Table.Summary.Row>
                                               </Table.Summary>
                                           );
                                       }}
                                />
                            </Row>
                        )
                    }
                    , {
                        key: '6', label: `领料详情`, children: (
                            <Row style={{marginTop: -20}}>
                                <Table bordered style={{marginTop: 6}} scroll={{x: 1100, y: 'calc(100vh - 210px)'}} pagination={false}
                                       columns={pickMatlColumns}
                                       dataSource={proMoData}
                                       size="small"
                                       rowKey={(record,i) => {return i}}
                                       expandable={{
                                           expandedRowRender: (record, index, indent, expanded) => expandedRowRender(record, index, indent, expanded),
                                           // expandedRowRender: record => (
                                           //     <p style={{ margin: 0 }}>{record.bbb}</p>
                                           // ),
                                           expandIconColumnIndex: 11,
                                           onExpand: async (expanded, record) => {},
                                       }}
                                />
                            </Row>
                        )
                    }
                ]}
            />}

            {chooseOrg.num == '0603' &&
            <Row>
                <Col span={12} style={styles.card}><ManuCard bk={'#EFF3FA'} msg={"订单额"} dat={mData.saleOrder}/></Col>
                <Col span={12} style={styles.card}><ManuCard bk={'#EFF3FA'} msg={"主营收入"} dat={mData.saleOutOrder}/></Col>
                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"产品发货及时率"} dat={mData.salOutStock}/></Col>
                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"供应链交付及时率"} dat={mData.purOrder}/></Col>
                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"生产订单完成及时率"} dat={mData.prdMo}/></Col>
                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"生产计划达成率"} dat={mData.prdInStock}/></Col>
                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"采购品验收合格率"} dat={mData.purReceive}/></Col>
                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"成品检验合格率"} dat={mData.zdpPrdMo}/></Col>
                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"生产过程检验合格率"} dat={mData.prdMoProc}/></Col>
                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"产品质量损失额"} dat={mData.zdpPrdMoLos}/></Col>
                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"BOM准确率"} dat={mData.prdBom}/></Col>
                <Col span={12} style={styles.card}><ManuCard bk={'#FAFAFA'} msg={"物流费用"} dat={mData.logiCost} pad={true}/></Col>
                <Col span={12} style={styles.card}><ManuCard bk={'#F9F5FA'} msg={"外协费用"} dat={mData.outSrc}/></Col>
            </Row>}
        </>
    );
};

export default ManufactStat;