import React from 'react';
import {Route} from 'react-router-dom'
import 'antd/dist/antd.css';
import ApprovalStat from "./pages/ApprovalStat";
import ApprovalDetail from "./pages/ApprovalDetail";
import PmBuildLog from "./pages/PmBuildLog";
import ManufactStat from "./pages/ManufactStat";
import CrmStat from "./pages/CrmStat";
import SysSetting from "./pages/SysSetting";

const App = () => {
    return (
        <>
            <Route exact component={ApprovalStat} path='/'/>
            <Route exact component={ApprovalDetail} path='/detail'/>
            <Route exact component={PmBuildLog} path='/pm'/>
            <Route exact component={ManufactStat} path='/manu'/>
            <Route exact component={CrmStat} path='/crm'/>
            <Route exact component={SysSetting} path='/sys'/>
        </>
    );
};

export default App;