// 公司ID 也可以后台接口读取
export const CORP_ID = "ding23f095ee7aceb41635c2f4657eb6378f";
// yujy 组织
// export const CORP_ID = "dingb1d544fcfb429078ffe93478753d9884";
// 域名
export const DOMAIN = "";
// export const DOMAIN = "http://localhost:8181";
// session变量
export const DING_AUTH_CD = 'auth-code';
export const DING_USER_INFO = 'user-info';
export const DING_USER_ID = 'user-id';
export const DING_JOB_NUM = 'job-num';
export const DING_USER_NM = 'user-name';
export const DING_USER_AVATAR = 'user-avatar';
export const DING_USER_ROLES = 'roles';
export const LOGIN_USER_TYPE = 'user-type';
export const KD_LOGIN_STATUS = 'kd-login-status';
export const AUTH_TOKEN = 'auth-token';
export const DOWNLOAD_DATA = 'download-data';

// 更新查询数据范围 范围
export const DATA_TYPE = [
    {value: 1, label: "采购流程审批统计"},
    {value: 2, label: "兆和数字指标统计"},
    {value: 3, label: "风管制造指标统计"},
];

// 日期 范围
export const DATE_RANGE = [
    {value: 1, label: "本周"},
    {value: 2, label: "上周"},
    {value: 3, label: "本月"},
    {value: 4, label: "上月"},
    {value: 10, label: "本季"},
    {value: 11, label: "上季"},
    {value: 15, label: "本年"}
];

// 日期 范围
export const DATE_RANGE_N = [
    {value: 1, label: "本周"},
    {value: 2, label: "上周"},
    {value: 3, label: "本月"},
    {value: 4, label: "上月"},
    {value: 10, label: "本季"},
    {value: 11, label: "上季"},
    {value: 15, label: "本年"},
    {value: 91, label: "月份"}
];

export const MANU_ORG_RANGE = [
    {value: 17, num: "0603", label: "风管制造事业部"},
    {value: 21, num: "08", label: "大连兆和数字环境工程研究中心有限公司"},
];

// 事业部 范围
export const ORG_RANGE = [
    {value: 1, num: "01", label: "苏州兆和空气系统股份有限公司"},
    {value: 2, num: "0101", label: "大连兆和总部"},
    {value: 3, num: "0102", label: "环保机电事业部"},
    {value: 4, num: "0103", label: "有机废气事业部"},
    {value: 5, num: "0104", label: "运维服务事业部"},
    {value: 6, num: "0105", label: "大连除尘系统事业部"},
    // {value: "02", label: "上海申兆和诚环境技术有限公司"},
    // {value: "03", label: "上海申兆和信环保设备有限公司"},
    {value: 9, num: "04", label: "天津兆和和兴机电安装工程有限责任公司"},
    {value: 10, num: "05", label: "兆和（苏州）智能装备科技有限公司"},
    {value: 11, num: "0501", label: "智能共享事业部"},
    {value: 12, num: "0502", label: "除尘系统事业部"},
    {value: 13, num: "0503", label: "换热设备事业部"},
    {value: 14, num: "06", label: "苏州兆和环能科技有限公司"},
    {value: 15, num: "0601", label: "环能共享事业部"},
    {value: 16, num: "0602", label: "工程技术事业部"},
    {value: 17, num: "0603", label: "风管制造事业部"},
    {value: 18, num: "0604", label: "环能设备事业部"},
    {value: 19, num: "0605", label: "换热事业部"},
    {value: 20, num: "07", label: "上海兆和通风工程有限公司"},
    {value: 21, num: "08", label: "大连兆和数字环境工程研究中心有限公司"},
    // {value: "11", label: "大连兆和环境科技股份有限公司保税区分公司"},
];

// 单据 范围
export const BILL_LIST = [
    {value: 1, label: "采购申请单"},
    {value: 2, label: "采购订单"},
    {value: 3, label: "收料通知单"},
    {value: 4, label: "采购入库单"},
    {value: 5, label: "付款申请单"},
    {value: 6, label: "付款单"}
];

// // 2023年度 外部订单目标额（固定值）
// export const SALE_TARGET_2023 = {
//     compTotal: 12,
//     company: [
//         {
//             name: '兆和集团',
//             value: 12, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0,
//             child: [
//                 {name: '兆和空气', value: 4, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//                 {name: '天津兆和', value: 0.6, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//                 {name: '苏州智能', value: 1.6, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//                 {name: '苏州环能', value: 4.8, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//                 {name: '上海兆和', value: 1, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//                 {name: '兆和数字', value: 0, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//             ],
//         },
//         {name: '兆和空气', value: 4, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//         {name: '天津兆和', value: 0.6, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//         {name: '苏州智能', value: 1.6, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//         {name: '苏州环能', value: 4.8, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//         {name: '上海兆和', value: 1, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//         {name: '兆和数字', value: 0, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//     ],
//     comp01Total: 4,
//     company01: [
//         {name: '环保机电', value: 2.5, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//         {name: 'voc事业部', value: 1.5, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//     ],
//     comp04Total: 0.6,
//     company04: [
//         {value: 0.6, name: '天津兆和', saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//     ],
//     comp05Total: 1.6,
//     company05: [
//         {value: 1.5, name: '除尘系统', saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//         {value: 0.1, name: '换热设备', saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//     ],
//     comp06Total: 4.8,
//     company06: [
//         {name: '工程技术', num: '0602', value: 3.6, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//         {name: '风管制造', num: '0603', value: 0.35, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//         {name: '环能设备', num: '0604', value: 0.4, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//         {name: '换热', num: '0605', value: 0.45, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//     ],
//     comp07Total: 1,
//     company07: [
//         {value: 1, name: '上海兆和', saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//     ],
//     comp08Total: 0,
//     company08: [
//         {value: 0, name: '兆和数字', saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0},
//     ],
// };

// 2024年度 销售订单目标额（固定值）
export const SALE_TARGET_2024 = {
    tab: [
        {
            name: '兆和集团', value: 12.21, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0,
            child: [
                {name: '兆和空气', value: 4.83, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0},
                {name: '天津兆和', value: 0.6, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0},
                {name: '苏州环能', value: 5.32, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0},
                {name: '上海兆和', value: 1.46, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0},
            ],
        },
        {
            name: '兆和空气', value: 4.83, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0,
            child: [
                {name: '环保机电', value: 2, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0},
                {name: 'voc事业部', value: 1.2, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0},
                {name: '除尘系统', value: 1.63, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0},
            ],
        },
        {
            name: '天津兆和', value: 0.6, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0,
            childTotal: 0.6,
            child: [
                {name: '天津兆和', value: 0.6, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0},
            ],
        },
        {
            name: '苏州环能', value: 5.32, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0,
            childTotal: 5.32,
            child: [
                {name: '环能销售\n工程+设备',num: '06',value: 5,saleAmt: 0,winAmt: 0,leftAmt: 0,nicheAmt: 0,keyNicheAmt: 0, returnAmt: 0},
                {name: '风管制造',num: '0603',value: 0.32,saleAmt: 0,winAmt: 0,leftAmt: 0,nicheAmt: 0,keyNicheAmt: 0, returnAmt: 0},
            ],
        },
        {name: '上海兆和', value: 1.46, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0,
            childTotal: 1.46,
            child: [
                {name: '上海兆和', value: 1.46, saleAmt: 0, winAmt: 0, leftAmt: 0, nicheAmt: 0, keyNicheAmt: 0, returnAmt: 0},
            ],
        }
    ]
};

export const locale = {
    lang: {
        locale: 'zh_CN',
        placeholder: '请选择日期',
        rangePlaceholder: ['Start date', 'End date'],
        today: 'Today',
        now: 'Now',
        backToToday: 'Back to today',
        ok: 'Ok',
        clear: 'Clear',
        month: 'Month',
        year: 'Year',
        timeSelect: 'Select time',
        dateSelect: 'Select date',
        monthSelect: 'Choose a month',
        yearSelect: 'Choose a year',
        decadeSelect: 'Choose a decade',
        yearFormat: 'YYYY年',
        dateFormat: 'M/D/YYYY',
        dayFormat: 'D',
        dateTimeFormat: 'M/D/YYYY HH:mm:ss',
        monthFormat: 'M月',
        monthBeforeYear: true,
        previousMonth: 'Previous month (PageUp)',
        nextMonth: 'Next month (PageDown)',
        previousYear: 'Last year (Control + left)',
        nextYear: 'Next year (Control + right)',
        previousDecade: 'Last decade',
        nextDecade: 'Next decade',
        previousCentury: 'Last century',
        nextCentury: 'Next century',
    },
    timePickerLocale: {
        placeholder: 'Select time',
    },
    dateFormat: 'YYYY-MM-DD',
    dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
    weekFormat: 'YYYY-wo',
    monthFormat: 'YYYY-MM',
};