import React from 'react';
import {Link} from 'react-router-dom'
import {Card, Col, Row} from 'antd';
import 'antd/dist/antd.css';

const AprCard = (props) => {
    // 页面样式
    const styles = {
        head: {background: '#FAFAFA', borderTopLeftRadius: 12, borderTopRightRadius: 12},
        body: {background: '#FAFAFA', borderBottomLeftRadius: 12, borderBottomRightRadius: 12, paddingTop: 0}
    };

    return (
        <Card size="small" bordered={false} title={props.msg} headStyle={styles.head} bodyStyle={styles.body}
              extra={<Link to={`/detail?tab=${props.tab}&range=${props.date}&org=${props.org}`}>></Link>}>
            <Row>
                <Col span={24}>
                    <span style={{fontSize: 30}}>{props.dat.hours}</span>
                    <span style={{marginLeft: 5}}>小时</span>
                </Col>
                <Col span={12} style={{marginTop: 5}}>
                    <span style={{fontSize: 13}}>单量:{props.dat.billCnt}</span>
                </Col>
                <Col span={12} style={{marginTop: 5, textAlign: "right", paddingRight: 10}}>
                    <span style={{fontSize: 13}}>人数:{props.dat.userCnt}</span>
                </Col>
            </Row>
        </Card>
    );
};

export default AprCard;