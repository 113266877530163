import CryptoJS from 'crypto-js'
// import html2canvas from "html2canvas";
// import {jsPDF} from "jspdf";

/**
 * AES加密 ：字符串 key iv  返回base64
 */
export const encrypt = (word) => {
    let key = CryptoJS.enc.Utf8.parse("abcdefgh1234mega");
    let src = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(src, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
};

/**
 1. AES 解密 ：字符串 key iv  返回base64
 2.  3. @return {string}
 */
export const decrypt = (word) => {
    if (word == undefined || word == '') {
        return '';
    }
    let key = CryptoJS.enc.Utf8.parse("abcdefgh1234mega");
    let decrypt = CryptoJS.AES.decrypt(word, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
};

// export const downLoadPDF = (dom, fileName) => {
//    // 取得滚动条距离顶部位置
//    let scrollTop = getScrollTop();
//    window.scrollTo(0,0);
//    html2canvas(dom, {
//       // allowTaint: true, //避免一些不识别的图片干扰，默认为false，遇到不识别的图片干扰则会停止处理html2canvas
//       // taintTest: false,
//       useCORS: true,
//       // Create a canvas with double-resolution.
//       scale: 2,
//       // Create a canvas with 144 dpi (1.5x resolution).
//       dpi: 192,
//       // 背景设为白色（默认为黑色）
//       background: "#fff",
//    }).then(function (canvas) {
//       // Html / Canvas 画面 尺寸
//       let contentWidth = canvas.width;
//       let contentHeight = canvas.height;
//
//       // 一页pdf显示html页面生成的canvas高度;（根据比例，算出来的固定值）
//       let htmlPageHeight = contentWidth / 595.28 * 841.89;
//       //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
//       let pdfPageWidth = 595.28;
//       let pdfPageHeight = 595.28 / contentWidth * contentHeight;
//       let pageData = canvas.toDataURL('image/jpeg', 1.0);
//       window.scrollTo(0,scrollTop);
//       // 画面尺寸小于 一页，则默认为A4，否则：设定指定高度画面
//       let pdf = new jsPDF('', 'pt', contentHeight < htmlPageHeight ? 'a4' : [pdfPageWidth, pdfPageHeight + 30]);
//       pdf.addImage(pageData, 'JPEG', 0, 0, pdfPageWidth, pdfPageHeight);
//
//       // 保存PDF文件
//       pdf.save(fileName);
//    });
// };

/**
 * 取得JSON对象的 value
 * @param original 原JSON对象
 * @param key 指定的key
 * @returns {string} 返回key对应的value
 */
export const getJsonValue = (original, key) => {
    let ret = '未知';
    for (let i = 0; i < original.length; i++) {
        if (original[i].value === key) {
            ret = original[i].label;
            break;
        }
    }
    return ret;
};

/**
 * 取得JSON对象的 item
 * @param original 原JSON对象
 * @param key 指定的key
 * @returns {string} 返回key对应的item
 */
export const getJsonItem = (original, key) => {
    let ret = {};
    for (let i = 0; i < original.length; i++) {
        if (original[i].value === key) {
            ret = original[i];
            break;
        }
    }
    return ret;
};

/**
 * 取得JSON对象的 item
 * @param original 原JSON对象
 * @param num 指定的Num
 * @returns {string} 返回key对应的item
 */
export const getJsonItemByNum = (original, num) => {
    let ret = {};
    for (let i = 0; i < original.length; i++) {
        if (original[i].num === num) {
            ret = original[i];
            break;
        }
    }
    return ret;
};

/**
 * 格式化日期基础函数，返回基础格式：'yyyy-MM-dd hh:mm:ss sss'
 * @param date 要格式化的日期
 * @returns {string} 'yyyy-MM-dd hh:mm:ss sss'
 */
export const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const hh = d.getHours().toString().padStart(2, '0');
    const mm = d.getMinutes().toString().padStart(2, '0');
    const ss = d.getSeconds().toString().padStart(2, '0');
    const sss = d.getMilliseconds().toString().padStart(3, '0');
    // es6 字符串模板
    return `${year}-${month}-${day} ${hh}:${mm}:${ss} ${sss}`;
};

/**
 * 格式化日期函数，返回日期格式：'yyyy-MM-dd hh:mm:ss'
 * @param date 要格式化的日期
 * @returns {string} 'yyyy-MM-dd hh:mm:ss'
 */
export const getDateTime = (date) => {
    // 'yyyy-MM-dd hh:mm:ss sss'
    let dateString = formatDate(date);
    // 'yyyy-MM-dd hh:mm:ss'
    return dateString.substr(0,19);
};

/**
 * 格式化日期函数，返回日期格式：'yyyy-MM-dd'
 * @param date 要格式化的日期
 * @returns {string} 'yyyy-MM-dd'
 */
export const getDate = (date) => {
    // 'yyyy-MM-dd hh:mm:ss sss'
    let dateString = formatDate(date);
    // 'yyyy-MM-dd'
    return dateString.substr(0,10);
};

/**
 * 格式化日期函数，返回日期格式：'yyyy-MM-dd'
 * @param date 要格式化的日期
 * @returns {string} 'yyyy-MM-dd'
 */
export const getYear = (date) => {
    // 'yyyy-MM-dd hh:mm:ss sss'
    let dateString = formatDate(date);
    // 'yyyy'
    return dateString.substr(0,4);
};

/**
 * 格式化日期函数，返回日期格式：'yyyyMMdd'
 * @param date 要格式化的日期
 * @returns {string} 'yyyyMMdd'
 */
export const getDateFormat = (date) => {
    // 'yyyy-MM-dd hh:mm:ss sss'
    let dateString = formatDate(date);
    // 'yyyyMMdd'
    return dateString.substr(0,10).replace(/-/g,'');
};

/**
 * 数字格式日期格式化函数，返回日期格式：'yyyy-MM-dd'
 * @param dateNum 数字格式日期
 * @returns {string} 'yyyy-MM-dd'
 */
export const number2date = (dateNum) => {
    let dateStr = dateNum + '';
    return dateStr.substring(0, 4) + '-' + dateStr.substring(4, 6) + '-' + dateStr.substring(6, 8);
};

/**
 * 两个浮点数 加法运算（解决JS浮点数 运算BUG）
 * @param num1
 * @param num2
 * @returns {string} 结果
 */
export const add = (num1, num2) => {
    let r1, r2, m;
    try {
        r1 = num1.toString().split('.')[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = num2.toString().split('.')[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2));
    return Math.round(num1 * m + num2 * m) / m;
};

/**
 * 两个浮点数 相减运算（解决JS浮点数 运算BUG）
 * @param arg1
 * @param arg2
 * @returns {string} 结果
 */
export const sub = (arg1, arg2) => {
    let r1, r2, m, n;
    try {
        r1 = arg1.toString().split('.')[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split('.')[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n)
};

/**
 * 数字格式化。
 * @param number 数字
 * @param decimals 保留小数位数
 * @returns {string} 标准格式 例：FormatUtil.formatNumber(123456.789, 2) ==> 123,456.79
 */
export const formatNumber = (number, decimals) => {
    decimals = typeof decimals === 'undefined' ? 0 : decimals;
    // 保留指定小数点后位数，并分割数组
    let formatNum = 0;
    if (isNumber(number)) {
        formatNum = number;
    } else if (typeof number === 'string') {
        formatNum = parseInt(number);
        formatNum = isNaN(parseInt(number)) ? 0 : formatNum;
    } else {
        formatNum = 0;
    }
    let x = formatNum.toFixed(decimals).split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1))
        x1 = x1.replace(rgx, '$1,$2');
    return x1 + x2;
};

/**
 * 货币格式化。
 * @param amount 货币
 * @returns {string} 人民币货币格式  例：FormatUtil.formatCurrency(123456.789) ==> CN¥123,456.79
 */
export const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CNY',
        currencyDisplay: "symbol"
    }).format(amount);
};

/**
 * 计算百分比
 * @param   {number} num   分子
 * @param   {number} total 分母
 * @returns {number} 返回数百分比
 */
export const percentage = (num, total) => {
    if (num == 0 || total == 0){
        return 0
    }
    return (Math.round(num / total * 10000) / 100.00) // 小数点后两位百分比
};

/**
 * 在本地进行文件保存
 * @param  {String} data     要保存到本地的文件数据
 * @param  {String} filename 文件名
 */
export const saveFile = (data, filename) => {
    let save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    save_link.href = data;
    save_link.download = filename;

    let event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    save_link.dispatchEvent(event);
};

function isNumber(val) {
    //非负浮点数
    let regPos = /^\d+(\.\d+)?$/;
    //负浮点数
    let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/;
    return regPos.test(val) || regNeg.test(val);
}

export const isPhone = (phone) => {
    let phoneReg = /^1[3-9]\d{9}$/;
    return phoneReg.test(phone);
};

export function mapToObj(strMap) {
    let obj = Object.create(null);
    for (let [key, value] of strMap) {
        obj[key] = value;
    }
    return obj;
}

export function objToMap(obj) {
    let strMap = new Map();
    for (let k of Object.keys(obj)) {
        strMap.set(k, obj[k]);
    }
    return strMap;
}

// function getScrollTop() {
//     let scrollTop = 0;
//     if (document.documentElement && document.documentElement.scrollTop) {
//         scrollTop = document.documentElement.scrollTop;
//     } else if (document.body) {
//         scrollTop = document.body.scrollTop;
//     }
//     return scrollTop;
// }

/**
 * 取得指定时间范围的开始日期，结束日期
 * @param type 范围类型，例如：本周，本月等
 * @returns {{}} 返回 {start: 开始日期, end: 结束日期}
 */
export function getDateRange(type) {
    //当前日期
    // let today = new Date(2023,2,12);
    let today = new Date();
    //构建当前日期,格式：2022-09-22 00:00:00
    let year = today.getFullYear(); //XXXX
    let month = today.getMonth(); //0~11
    // let day = today.getDate(); //1~31

    // let newDate = new Date(year, month, day); //年月日拼接
    let nowTime = today.getTime(); //当前的时间戳
    let weekDay = today.getDay(); //当前星期 0.1.2.3.4.5.6 【0 = 周日】
    let oneDayTime = 24 * 60 * 60 * 1000; //一天的总ms

    let mondayTime = '';
    let lastMonday = '';
    let lastSunday = '';
    // 周日的话
    if (weekDay === 0) {
        mondayTime = new Date(nowTime - 6 * oneDayTime); //本周一的时间戳
        lastMonday = new Date(nowTime - 13 * oneDayTime); //上周一的时间戳
        lastSunday = new Date(nowTime - 7 * oneDayTime); //上周日的时间戳
    } else {
        mondayTime = new Date(nowTime - (weekDay - 1) * oneDayTime); //本周一的时间戳
        lastMonday = new Date(nowTime - (weekDay + 6) * oneDayTime); //上周一的时间戳
        lastSunday = new Date(nowTime - (weekDay) * oneDayTime); //上周日的时间戳
    }

    // 本月第一天
    let monthFirstDay = new Date(year, month, 1);
    // 上月第一天
    let lastMonthStart = new Date(year, (month - 1), 1);
    // 上月最后一天
    let lastMonthEnd = new Date(monthFirstDay.getTime() - oneDayTime);
    // 本季度第一天
    let seasonFirstDay = new Date(year, Math.floor(month / 3) * 3, 1);
    // 上季度第一天
    let preSeasonFirstDay = new Date(year, (Math.floor(month / 3) - 1) * 3, 1);
    // 上季度最后一天
    let preSeasonEndDay = new Date(seasonFirstDay.getTime() - oneDayTime);
    // 本年第一天
    let yearFirstDay = new Date(year, 0, 1);

    // // 7天前
    // let before7Day = new Date(nowTime - 7 * oneDayTime);
    // let before14Day = new Date(nowTime - 14 * oneDayTime);
    // let before30Day = new Date(nowTime - 30 * oneDayTime);

    let dateRange = {};
    switch (type) {
        case "本周":
            // 今天是周一时，结束也是周一，否则，结束是当前天-1
            let wEndD = (getDate(today) === getDate(new Date(mondayTime))) ? getDate(today) : getDate(new Date(today.getTime() - oneDayTime));
            dateRange = {start: getDate(new Date(mondayTime)), end: wEndD};
            break;
        case "上周":
            dateRange = {start: getDate(lastMonday), end: getDate(lastSunday)};
            break;
        case "本月":
            // 今天是1号时，结束也是1号，否则，结束是当前天-1
            let mEndD = (getDate(today) === getDate(monthFirstDay)) ? getDate(today) : getDate(new Date(today.getTime() - oneDayTime));
            dateRange = {start: getDate(monthFirstDay), end: mEndD};
            break;
        case "上月":
            dateRange = {start: getDate(lastMonthStart), end: getDate(lastMonthEnd)};
            break;
        case "本季":
            // 今天是季度开始日时，结束也是开始日，否则，结束是当前天-1
            let sEndD = (getDate(today) === getDate(seasonFirstDay)) ? getDate(today) : getDate(new Date(today.getTime() - oneDayTime));
            dateRange = {start: getDate(seasonFirstDay), end: sEndD};
            break;
        case "上季":
            dateRange = {start: getDate(preSeasonFirstDay), end: getDate(preSeasonEndDay)};
            break;
        case "本年":
            // 今天是1月1号时，结束也是1月1号，否则，结束是当前天-1
            let yEndD = (getDate(today) === getDate(yearFirstDay)) ? getDate(today) : getDate(new Date(today.getTime() - oneDayTime));
            dateRange = {start: getDate(yearFirstDay), end: yEndD};
            break;
        // case "近7天":
        //     dateRange = {start: getDate(before7Day), end: getDate(today)};
        //     break;
        // case "近14天":
        //     dateRange = {start: getDate(before14Day), end: getDate(today)};
        //     break;
        // case "近30天":
        //     dateRange = {start: getDate(before30Day), end: getDate(today)};
        //     break;
        default :
            break;
    }
    return dateRange;
}

/**
 * 取得指定时间范围的结束日期
 * @param startDate 时间范围：开始日期
 * @param endDate 时间范围：结束日期
 * @returns {{}} 返回 时间范围：结束日期}
 */
export function getPreDate(startDate, endDate) {
    // 返回结束日期
    let ret = '';
    //当前日期
    let today = new Date();

    // 结束日期 为 当天（本周，本月，本年的情况下 满足次判断）
    if (getDate(today)  === getDate(new Date(endDate))) {
        // 当前时间 是 （本周，本月，本年）的第一天时
        if (getDate(today) === startDate) {
            ret = startDate;
        } else {
            ret = getDate(new Date(today.getTime() - 24 * 60 * 60 * 1000));
        }
    } else {
        ret = endDate;
    }
    return ret;
}