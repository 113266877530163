import React from 'react';
import {Anchor, Col, Row, Divider, DatePicker} from 'antd';
import {CaretDownOutlined, SyncOutlined, DownloadOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';

const appAction = require('../utils/AppAction');
const sysConst = require('../utils/SysConst');

const disabledDate = (current) => {
    // 2023年之前 和 未来月份不能选额
    return current < dayjs('2023-01-01') || dayjs().add(-1,'month').endOf('month') < current;
};

const AprHead = (props) => {
    return (
        <Anchor style={{background: 'white'}}>
            {/* 头部 选择日期 以及 刷新 */}
            <Row style={{padding: 10}}>
                <Col span={6}>
                    <span onClick={() => {props.setTimeOpen(true)}} style={{fontWeight: "bold", fontSize: 16}}>
                        {props.chooseTime.label} <CaretDownOutlined style={{fontSize: 10}}/>
                    </span>
                    <Divider type="vertical"/>
                </Col>

                {props.chooseTime.label === '月份' &&
                <Col span={15}>
                    <DatePicker picker="month" locale={sysConst.locale} disabledDate={disabledDate} style={{height: 16}}
                                value={props.selectedMon} bordered={false}
                                onChange={(date,dateString) => {props.changeMon(date,dateString)}}
                    />
                </Col>}

                {/* 日期范围 */}
                {props.chooseTime.label !== '月份' &&
                <Col span={15}>{props.dateRange.start} ～ {props.dateRange.end}</Col>}

                {/* 刷新图标 */}
                {props.showDownload &&
                <Col span={2} style={{textAlign: "right", paddingRight: 10}}>
                    <DownloadOutlined style={{fontSize: 16}} onClick={() => {
                        appAction.loginDingTalk(
                            () => {
                                props.download()
                            }
                        );
                    }}/>
                </Col>}
                <Col span={props.showDownload ? 1 : 3} style={{textAlign: "right", paddingRight: 10}}>
                    <SyncOutlined style={{fontSize: 16}} onClick={() => {
                        appAction.loginDingTalk(
                            () => {
                                props.refresh()
                            }
                        );
                    }}/>
                </Col>
            </Row>

            <div style={{background: "#FAFAFA", height: 2}}/>

            {/* 头部 选择事业部 以及 显示事业部 */}
            <Row style={{padding: 10}}>
                <Col span={6}>
                    <span onClick={() => {props.setOrgOpen(true)}} style={{fontWeight: "bold", fontSize: 16}}>
                        事业部 {props.showIcon && <CaretDownOutlined style={{fontSize: 10}}/>}
                    </span>
                    <Divider type="vertical"/>
                </Col>
                {/* 事业部范围 */}
                <Col span={18}>{props.chooseOrg.label}</Col>
            </Row>

            <div style={{background: "#FAFAFA", height: 5}}/>
        </Anchor>
    );
};

export default AprHead;