import React from 'react';
import {Col, Row, Drawer, Button} from 'antd';
import 'antd/dist/antd.css';

const sysConst = require('../utils/SysConst');

const ManuOrgListDrawer = (props) => {
    return (
        <Drawer placement="bottom" title={<div style={{width: "85%", textAlign: "center"}}>选择事业部</div>}
                onClose={() => {props.setOpen(false)}} open={props.orgOpen}>
            <Row>
                {sysConst.MANU_ORG_RANGE.map(function (item, index) {
                    return (
                        <Col span={24} style={{margin: 0, padding: 0}} key={index}>
                            <Button type="text" block style={{margin: 0, padding: 0, textAlign: "left"}}
                                    onClick={() => {
                                        props.changeOrg(item)
                                    }}>
                                {item.num}：{item.label}
                            </Button>
                        </Col>
                    )
                })}
            </Row>
        </Drawer>
    );
};

export default ManuOrgListDrawer;