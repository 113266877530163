import React, {useEffect, useState} from 'react'
import {Spin, Tabs} from 'antd';
import axios from "axios";
import qs from "querystring";
import DateRangeDrawer from "../component/DateRangeDrawer";
import AprTable from "../component/AprTable";
import AprHead from "../component/AprHead";
import OrgListDrawer from "../component/OrgListDrawer";

const sysConst = require('../utils/SysConst');
const commonUtil = require('../utils/CommonUtil');
const dingUtil = require('../utils/DingUtils');
const appAction = require('../utils/AppAction');

const ApprovalDetail = (props) => {
    // 页面调用接口时，是否显示加载中动画
    const [loading, setLoading] = useState(false);
    // 选择日期抽屉 状态
    const [timeOpen, setTimeOpen] = useState(false);
    // 选择事业部抽屉 状态
    const [orgOpen, setOrgOpen] = useState(false);
    // 时间范围
    const [chooseTime, setChooseTime] = useState(sysConst.DATE_RANGE[2]);
    const [dateRange, setDateRange] = useState({start: "", end: ""});
    // 事业部范围
    const [chooseOrg, setChooseOrg] = useState(sysConst.ORG_RANGE[0]);
    // TAB标签 默认索引
    const [tabIndex, setTabIndex] = useState("1");
    // 采购申请单 相关统计数据（hours：单据平均耗时，billCnt：单据量，userCnt：审批人数，eChartOpt：图表参数）
    const [purchaseReqData, setPurchaseReqData] = useState({hours: 0,billCnt: 0,userCnt: 0,dataList: []});
    // 采购订单
    const [purchaseOrdData, setPurchaseOrdData] = useState({hours: 0,billCnt: 0,userCnt: 0,dataList: []});
    // 收料通知单
    const [recMateInfoData, setRecMateInfoData] = useState({hours: 0,billCnt: 0,userCnt: 0,dataList: []});
    // 采购入库单
    const [purCInStoreData, setPurCInStoreData] = useState({hours: 0,billCnt: 0,userCnt: 0,dataList: []});
    // 付款申请单
    const [paymentReqData, setPaymentReqData] = useState({hours: 0,billCnt: 0,userCnt: 0,dataList: []});
    // 付款单
    const [paymentData, setPaymentData] = useState({hours: 0,billCnt: 0,userCnt: 0,dataList: []});

    // 第一次渲染时调用
    useEffect(() => {
        // 使用手机钉钉打开时, 设置标题内容
        dingUtil.setDingTitle('采购审批详情');
        // 通过link的search对象 取得参数
        let params = qs.parse(props.location.search.slice(1));

        // TAB标签 默认索引
        let defaultTab = "1";
        // 日期范围 默认值
        let defaultTime = sysConst.DATE_RANGE[2];
        // 事业部 默认值
        let defaultOrg = sysConst.ORG_RANGE[0];
        // 有参数（页面跳转时）
        if (JSON.stringify(params)!=="{}") {
            // 使用参数传递值
            defaultTime = commonUtil.getJsonItem(sysConst.DATE_RANGE, parseInt(params.range));
            defaultOrg = commonUtil.getJsonItem(sysConst.ORG_RANGE, parseInt(params.org));
            defaultTab = params.tab;
        }
        setChooseTime(defaultTime);
        setChooseOrg(defaultOrg);
        changeTab(defaultTab);
        getBillData(defaultTime.label, defaultOrg.num, commonUtil.getJsonValue(sysConst.BILL_LIST, parseInt(defaultTab)));
    }, []);

    // table 固定表头
    const columns = [
        {title: '序号', fixed: true, width: 76, align: 'center', dataIndex: 'key', sorter: (a, b) => a.key - b.key},
        {title: '姓名', fixed: true, width: 76, align: 'center', dataIndex: 'USER_NAME'},
        {
            title: '单量(单)',
            width: 76,
            align: 'center',
            dataIndex: 'BILL_COUNT',
            // 取消默认排序，只能升序，降序 来回切换
            // sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.BILL_COUNT - b.BILL_COUNT
        },
        {
            title: '平均耗时(小时)',
            align: 'center',
            dataIndex: 'AVERAGE_HOURS',
            sorter: (a, b) => a.AVERAGE_HOURS - b.AVERAGE_HOURS
        }
    ];

    // 调用金蝶 采购流程统计 接口，取得 相关单据统计数据
    const getKdPurProStat = async (rangeName, orgNum, billName) => {
        // 金蝶未登录时，先执行金蝶登录
        // if (!localUtil.getSessionItem(sysConst.KD_LOGIN_STATUS)) {
        //     await appAction.loginKd();
        // }
        // 加载中
        setLoading(true);
        // 日期范围参数
        let dateRangeParam = commonUtil.getDateRange(rangeName);
        setDateRange(dateRangeParam);
        // 初始化数据
        setPurchaseReqData({hours: 0, billCnt: 0, userCnt: 0, dataList: []});
        setPurchaseOrdData({hours: 0, billCnt: 0, userCnt: 0, dataList: []});
        setRecMateInfoData({hours: 0, billCnt: 0, userCnt: 0, dataList: []});
        setPurCInStoreData({hours: 0, billCnt: 0, userCnt: 0, dataList: []});
        setPaymentReqData({hours: 0, billCnt: 0, userCnt: 0, dataList: []});
        setPaymentData({hours: 0, billCnt: 0, userCnt: 0, dataList: []});
        // 调用金蝶接口
        axios.post(sysConst.DOMAIN + "/kd/purchase/process/statistics", {
            orgNum:orgNum, billName: billName, startDay: dateRangeParam.start, endDay: dateRangeParam.end
        }).then(res => {
            if (res.data.success) {
                // 数组序号
                let arr = [];
                switch (billName) {
                    case "采购申请单":
                        res.data['采购申请单'].data.forEach((currentValue, index) => {
                            arr.push({...currentValue, key: index + 1})
                        });
                        setPurchaseReqData({
                            hours: res.data['采购申请单'].average_hours,
                            billCnt: res.data['采购申请单'].bill_count,
                            userCnt: res.data['采购申请单'].rowcount,
                            dataList: arr
                        });
                        break;
                    case "采购订单":
                        // 数组序号
                        res.data['采购订单'].data.forEach((currentValue, index) => {
                            arr.push({...currentValue, key: index + 1})
                        });
                        setPurchaseOrdData({
                            hours: res.data['采购订单'].average_hours,
                            billCnt: res.data['采购订单'].bill_count,
                            userCnt: res.data['采购订单'].rowcount,
                            dataList: arr
                        });
                        break;
                    case "收料通知单":
                        // 数组序号
                        res.data['收料通知单'].data.forEach((currentValue, index) => {
                            arr.push({...currentValue, key: index + 1})
                        });
                        setRecMateInfoData({
                            hours: res.data['收料通知单'].average_hours,
                            billCnt: res.data['收料通知单'].bill_count,
                            userCnt: res.data['收料通知单'].rowcount,
                            dataList: arr
                        });
                        break;
                    case "采购入库单":
                        // 数组序号
                        res.data['采购入库单'].data.forEach((currentValue, index) => {
                            arr.push({...currentValue, key: index + 1})
                        });
                        setPurCInStoreData({
                            hours: res.data['采购入库单'].average_hours,
                            billCnt: res.data['采购入库单'].bill_count,
                            userCnt: res.data['采购入库单'].rowcount,
                            dataList: arr
                        });
                        break;
                    case "付款申请单":
                        // 数组序号
                        res.data['付款申请单'].data.forEach((currentValue, index) => {
                            arr.push({...currentValue, key: index + 1})
                        });
                        setPaymentReqData({
                            hours: res.data['付款申请单'].average_hours,
                            billCnt: res.data['付款申请单'].bill_count,
                            userCnt: res.data['付款申请单'].rowcount,
                            dataList: arr
                        });
                        break;
                    case "付款单":
                        // 数组序号
                        res.data['付款单'].data.forEach((currentValue, index) => {
                            arr.push({...currentValue, key: index + 1})
                        });
                        setPaymentData({
                            hours: res.data['付款单'].average_hours,
                            billCnt: res.data['付款单'].bill_count,
                            userCnt: res.data['付款单'].rowcount,
                            dataList: arr
                        });
                        break;
                    default :
                        break;
                }
            } else {
                if (!res.data.success) {
                    // 会话信息已丢失，请重新登录
                    // alert(res.data.message);
                    // appAction.loginKd();
                    alert("调用金蝶API异常，请尝试刷新页面！");
                } else {
                    alert(`getKdPurProStat no data`)
                }
            }
            setLoading(false);
        }).catch(error => {
            console.log("ApprovalDetail getKdPurProStat err, " + JSON.stringify(error));
            setLoading(false);
        })
    };

    const getBillData = (rangeName, orgNum, billName) => {
        // 取得钉钉登录信息
        appAction.loginDingTalk(
            () => {
                // 调用金蝶接口 取得数据
                getKdPurProStat(rangeName, orgNum, billName);
            }
        );
    };

    const changeTab = (key) => {
        setTabIndex(key);
    };

    const onTabClick = (key) => {
        getBillData(chooseTime.label, chooseOrg.num, commonUtil.getJsonValue(sysConst.BILL_LIST, parseInt(key)));
    };

    const changeTime = (rangeItem) => {
        // 关闭 抽屉
        setTimeOpen(false);
        // 更新 选中值
        setChooseTime(rangeItem);
        // 调用金蝶接口 取得数据
        getBillData(rangeItem.label, chooseOrg.num, commonUtil.getJsonValue(sysConst.BILL_LIST, parseInt(tabIndex)));
    };

    const changeOrg = (orgItem) => {
        // 关闭 抽屉
        setOrgOpen(false);
        // 更新 选中值
        setChooseOrg(orgItem);
        // 调用金蝶接口 取得数据
        getBillData(chooseTime.label, orgItem.num, commonUtil.getJsonValue(sysConst.BILL_LIST, parseInt(tabIndex)));
    };

    // 刷新
    const refresh = () => {
        getBillData(chooseTime.label, chooseOrg.num, commonUtil.getJsonValue(sysConst.BILL_LIST, parseInt(tabIndex)));
    };

    return (
        <>
            {/* 加载中 动画，居中显示 */}
            <div style={{zIndex: 999,position:'absolute',top:'40%',left:'46%'}}>
                <Spin size="large" spinning={loading}/>
            </div>

            {/* 审批统计 固定头部内容，【时间范围：本周，本月，本年等】【事业部：大连兆和总部等等...】 */}
            <AprHead setTimeOpen={setTimeOpen} chooseTime={chooseTime} dateRange={dateRange} refresh={refresh}
                     setOrgOpen={setOrgOpen} chooseOrg={chooseOrg} showIcon={true}/>

            {/* 选择日期 抽屉 */}
            <DateRangeDrawer timeOpen={timeOpen} setOpen={setTimeOpen} changeTime={changeTime}/>

            {/* 选择事业部 抽屉 */}
            <OrgListDrawer orgOpen={orgOpen} setOpen={setOrgOpen} changeOrg={changeOrg}/>

            <div style={{paddingLeft: 5, paddingRight: 5}}>
                <Tabs
                    activeKey={tabIndex}
                    onChange={changeTab}
                    onTabClick={onTabClick}
                    items={[
                        {key: '1',label: `采购申请单`,children: (<AprTable columns={columns} data={purchaseReqData}/>)},
                        {key: '2',label: `采购订单`,children: (<AprTable columns={columns} data={purchaseOrdData}/>)},
                        {key: '3',label: `收料通知单`,children: (<AprTable columns={columns} data={recMateInfoData}/>)},
                        {key: '4',label: `采购入库单`,children: (<AprTable columns={columns} data={purCInStoreData}/>)},
                        {key: '5',label: `付款申请单`,children: (<AprTable columns={columns} data={paymentReqData}/>)},
                        {key: '6',label: `付款单`,children: (<AprTable columns={columns} data={paymentData}/>)},
                    ]}
                />
            </div>
        </>
    )
};

export default ApprovalDetail;