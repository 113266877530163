import React from 'react';
import {Col, Row, Popover} from 'antd';
import 'antd/dist/antd.css';
import {QuestionCircleOutlined} from '@ant-design/icons';

const ManuListCard = (props) => {
    // 页面样式
    const styles = {
        body: {borderRadius: 5, padding: 5, background: props.bk},
        topl: {paddingLeft: 1, paddingBottom: 1, textAlign: props.dat.textAlign},
        botl: {paddingLeft: 2}
    };

    const popoverContent = (
        <div style={{fontSize:10}}>
            <Col span={24}>{props.dat.ruleT}</Col>
            <Col span={24}>{props.dat.ruleC}</Col>
            <Col span={24}>{props.dat.ruleB}</Col>
        </div>
    );

    return (
        <Row style={styles.body}>
            <Col span={24} style={styles.topl}>
                <span style={{fontSize: 12}}>
                    <Popover content={popoverContent}>
                        {props.msg} <QuestionCircleOutlined style={{fontSize: 11}}/>：{props.dat.main}
                    </Popover>
                </span>
            </Col>

            {/* 20. 班组任务完成及时率 */}
            {props.dat.listT == 'groupTask' && props.dat.list.map(function (item, index) {
                return (
                    <Col span={24} style={styles.botl} key={index}>
                        <span style={{fontSize: 11}}>
                            {item.team_name}：{item.rate + '%'} <span style={{color: "grey"}}>({item.numerator}/{item.denominator})</span>
                        </span>
                    </Col>
                )
            })}
            {/* 16. 有效工时占比 */}
            {props.dat.listT == 'workHours' && props.dat.list.map(function (item, index) {
                return (
                    <Col span={24} style={styles.botl} key={index}>
                        <span style={{fontSize: 11}}>
                            {item.team_name}：{item.work_rate + '%'} <span style={{color: "grey"}}>({item.work_time}/{item.ding_hour})</span>
                        </span>
                    </Col>
                )
            })}
            {/* 1.订单额 和 主营收入 */}
            {props.dat.listT == 'saleOrder' && props.dat.list.map(function (item, index) {
                return (
                    <Col span={24} style={styles.botl} key={index}>
                        <span style={{fontSize: 11}}>
                            {item.org_name}：{item.order_amt}({item.order_cnt + '单'})
                        </span>
                    </Col>
                )
            })}
        </Row>
    );
};

export default ManuListCard;